import { lazy } from 'react'
import { LoopModule } from 'src/services/openApiV2'

// Marketing New UI
const MarketingNew = lazy(() => import('src/pages/members/MarketingNew/Marketing'))
const MarketingCampaignManagement = lazy(() => import('src/pages/members/MarketingCampaignManagement'))
const MarketingCompetitionAnalysis = lazy(() => import('src/pages/members/MarketingCompetitionAnalysis'))
const MarketingEvaluation = lazy(() => import('src/pages/members/MarketingNew/MarketingEvaluation'))
const MarketingTrends = lazy(() => import('src/pages/members/MarketingPages/Trends/Trends'))
const MarketingStoreMetrics = lazy(() => import('src/pages/members/MarketingPages/StoreMetrics/StoreMetrics'))
const MarketingCampaignMetrics = lazy(() => import('src/pages/members/MarketingPages/CampaignMetrics/CampaignMetrics'))
const MarketingConversionMetrics = lazy(() => import('src/pages/members/MarketingPages/ConversionMetrics/ConversionMetrics'))
const MarketingCompetitiveAnalysisItems = lazy(() => import('src/pages/members/MarketingPages/CompetitionAnalysisItems/CompetitionAnalysisItems'))
const MarketingCompetitiveAnalysisStores = lazy(() => import('src/pages/members/MarketingPages/CompetitionAnalysisStores/CompetitionAnalysisStores'))
const MarketingStoresRanking = lazy(() => import('src/pages/members/MarketingPages/StoresRanking/StoresRanking'))
const MarketingCOGS = lazy(() => import('src/pages/members/MarketingNew/Cogs'))
const Benchmarks = lazy(() => import('src/pages/members/Benchmarks/Benchmarks'))
const BenchmarksOld = lazy(() => import('src/pages/members/BenchmarksOld/Benchmarks'))
const MarketingCustomerRetention = lazy(() => import('src/pages/members/CustomerRetention/CustomerRetention'))
const MarketingItemRetention = lazy(() => import('src/pages/members/CustomerRetention/ItemRetention'))
const Leaderboard = lazy(() => import('src/pages/members/Leaderboard'))
const HeadroomPage = lazy(() => import('src/pages/members/MarketingPages/HeadroomAnalysis/Components/HeadroomPage'))

export const existingRoutes = [
  { path: 'marketing/new', element: <MarketingNew /> },
  {
    path: 'marketing/campaign/management',
    element: <MarketingCampaignManagement />
  },
  {
    path: 'marketing/competition/analysis',
    element: <MarketingCompetitionAnalysis />
  },
  {
    path: 'marketing/evaluation',
    element: <MarketingEvaluation />
  },
  {
    path: 'marketing/trends',
    element: <MarketingTrends />
  },
  {
    path: 'marketing/cogs',
    element: <MarketingCOGS />
  },
  {
    path: 'marketing/store_metrics',
    element: <MarketingStoreMetrics />
  },
  {
    path: 'marketing/campaign_metrics',
    element: <MarketingCampaignMetrics />
  },
  {
    path: 'marketing/conversion_metrics',
    element: <MarketingConversionMetrics />
  },
  {
    path: 'marketing/competition_analysis_items',
    element: <MarketingCompetitiveAnalysisItems />
  },
  {
    path: 'marketing/competition_analysis_stores',
    element: <MarketingCompetitiveAnalysisStores />
  },
  {
    path: 'marketing/stores_ranking',
    element: <MarketingStoresRanking />
  },
  {
    path: 'tru-roi/benchmarks',
    element: <Benchmarks module={LoopModule.TRUROI} />
  },
  {
    path: 'marketing/customer_retention',
    element: <MarketingCustomerRetention />
  },
  {
    path: 'marketing/leaderboard',
    element: <Leaderboard />
  }
]

export const moduleNamedRoutes = [
  {
    path: 'tru-roi',
    children: [
      { index: true, element: <MarketingNew /> },
      { path: 'new', element: <MarketingNew /> },
      {
        path: 'campaign-management',
        element: <MarketingCampaignManagement />
      },
      {
        path: 'competition-analysis',
        element: <MarketingCompetitionAnalysis />
      },
      {
        path: 'evaluation',
        element: <MarketingEvaluation />
      },
      {
        path: 'trends',
        element: <MarketingTrends />
      },
      {
        path: 'cogs',
        element: <MarketingCOGS />
      },
      {
        path: 'store-metrics',
        element: <MarketingStoreMetrics />
      },
      {
        path: 'campaign-metrics',
        element: <MarketingCampaignMetrics />
      },
      {
        path: 'conversion-metrics',
        element: <MarketingConversionMetrics />
      },
      {
        path: 'competition-analysis-items',
        element: <MarketingCompetitiveAnalysisItems />
      },
      {
        path: 'competition-analysis-stores',
        element: <MarketingCompetitiveAnalysisStores />
      },
      {
        path: 'stores-ranking',
        element: <MarketingStoresRanking />
      },
      {
        path: 'benchmarks',
        element: <Benchmarks module={LoopModule.TRUROI} />
      },
      {
        path: 'loop-benchmarks',
        element: <BenchmarksOld />
      },
      {
        path: 'customer-retention',
        element: <MarketingCustomerRetention />
      },
      {
        path: 'item-retention',
        element: <MarketingItemRetention />
      },
      {
        path: 'leaderboard',
        element: <Leaderboard />
      },

      {
        path: 'headroom',
        element: <HeadroomPage />
      }
    ]
  }
]

export const truRoiRoutes = [...existingRoutes, ...moduleNamedRoutes]
