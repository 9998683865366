import HyperDX from '@hyperdx/browser'
import { wrapUseRoutes } from '@sentry/react'
import { doc, onSnapshot } from 'firebase/firestore'
import posthog from 'posthog-js'
import React, { lazy, useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom'
import Loader from 'src/components/mui/Loader'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { WizardType } from 'src/context/WizardContext'
import NotFound from 'src/pages/public/notFound/NotFound'
import { fetchUserOrgAndRole, firebaseDb, getWizardRoutes } from 'src/services/firebase'
import { sendSlackNotification } from 'src/utils/api'
import { AccessLevelEnum, APP_NAME, APP_VERSION } from 'src/utils/config/config'
import { capitalize, get } from 'src/utils/config/lodashUtils'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { getSession, setSession } from 'src/utils/functions/localStorage'
import useStandaloneRouteData from 'src/utils/hooks/useStandaloneRouteData'
import { PrivateLayout } from '../../layout'
import { balanceRoutes } from './balancePrivateRouter'
import { baseRoutes } from './basePrivateRouter'
import { coreRoutes } from './corePrivateRouter'
import { generalRoutes } from './generalRoutes'
import { guardRoutes } from './guardPrivateRouter'
import { recoverRoutes } from './recoverPrivateRouter'
import { reEngageRoutes } from './reEngagePrivateRouter'
import { truRoiRoutes } from './truRoiPrivateRouter'

// Lazy-loaded components
const HomePage = lazy(() => import('src/pages/members/HomePage/HomePage'))
const NotificationCenter = lazy(() => import('src/pages/members/Notifications/NotificationCenter'))
const MagicLinkExpired = lazy(() => import('src/pages/public/Login/MagicLinkExpired'))
const StudioContainer = lazy(() => import('src/pages/members/StudioContainer'))
const SupersetDashboard = lazy(() => import('src/pages/members/SupersetDashboard'))
const LinkAuthProvider = lazy(() => import('src/pages/members/LinkAuthProvider/LinkAuthProvider'))
const DataRefreshStatus = lazy(() => import('src/pages/members/DataRefreshStatus'))
const OwnerDetails = lazy(() => import('src/pages/members/OwnerDetails/OwnerDetails'))
const SentryError = lazy(() => import('src/pages/members/Test'))
const SlackOAuth = lazy(() => import('src/pages/members/SlackOAuth/SlackOAuth'))
const UserProfile = lazy(() => import('src/pages/members/User/UserProfile/UserProfile'))
const UserManagement = lazy(() => import('src/pages/members/User/Mangement/UserManagement'))
const Wizard = lazy(() => import('src/pages/members/Wizard/Wizard'))
const Integrations = lazy(() => import('src/pages/members/Integrations/Integrations'))
const OnboardUberEats = lazy(() => import('src/pages/members/Integrations/OnboardUberEats'))

const useSentryRoutes = wrapUseRoutes(useRoutes)

const PrivateRouter = React.memo(() => {
  const location = useLocation()
  const { state } = useLocation()
  const { getFilters, dateRange, refreshFilters } = useFilter()
  const {
    currentUser,
    googleStudioLinks,
    supersetLinks,
    orgConfig,
    globalRoutes,
    getApiData,
    logout,
    hasMagicLink,
    setStandaloneRoutesLoaded,
    getRoutes,
    getWizards,
    isOrgInActive
  } = useAuth()
  const { generateStandaloneRoutes } = useStandaloneRouteData()
  const { setPrivateRoutes, routes } = usePrivateLayoutDrawerContext()

  const [filteredRouteList, setFilteredRouteList] = useState<RouteObject[]>([])
  const [wizardRoutes, setWizardRoutes] = useState<WizardType[]>([])

  // =========== Memoized Values ===========
  const navigateLink = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const redirectLink = params.get('redirect')

    if ((currentUser && get(currentUser, 'stage') === 'waiting' && !hasMagicLink) || isOrgInActive) {
      return '/create-account/wait'
    }
    return redirectLink || 'home'
  }, [location.search, currentUser, hasMagicLink, isOrgInActive])

  const flatRoute = useMemo(() => {
    const flatRoutes = routes
      .filter((e) => !!e)
      .map((route) => {
        const arr: string[] = []
        if ('subNav' in route && Array.isArray(route['subNav']) && route['subNav'].length > 0) {
          let subArr: string[] = route['subNav']
            .filter((subNav) => 'key' in subNav && typeof subNav.key === 'string')
            .map((subNav) => (subNav.key[0] === '/' ? subNav.key.slice(1) : `${route.key}/${subNav.key}`))
          arr.push(...subArr)
        } else {
          arr.push(route.key)
        }
        return arr
      })
      .reduce((prev, curr) => [...prev, ...curr], [])
    return flatRoutes
  }, [routes])

  const supersetRoutes = useMemo(() => {
    return supersetLinks.map((item) => {
      const route = routes.find(
        (e) =>
          e.key === item.key ||
          (e &&
            Array.isArray(e.subNav) &&
            e.subNav.some((subNav) => (subNav?.key?.startsWith('/') ? subNav?.key === item?.key : `${e.key}/${subNav?.key}` === item.key)))
      )
      return {
        path: item.key,
        element: (
          <SupersetDashboard
            title={get(route, 'label', null)}
            dashboard_id={item.dashboard_id}
          />
        )
      }
    })
  }, [supersetLinks, routes])

  const flattenPrivateRoutes = useCallback((routes: RouteObject[]) => {
    return routes.reduce((acc, route) => {
      if (!route.children) {
        return [...acc, route]
      }

      const indexRoute = route.children.find((child) => child.index)
      if (indexRoute) {
        acc.push({
          path: route.path,
          element: indexRoute.element
        })
      }

      const childrenWithBasePath = route.children
        .filter((child) => !child.index)
        .map((child) => ({
          ...child,
          path: child.path ? `${route.path}/${child.path}` : route.path
        }))

      acc.push(...flattenPrivateRoutes(childrenWithBasePath))
      return acc
    }, [] as RouteObject[])
  }, [])

  const privateRouteList: RouteObject[] = useMemo(
    () => [
      ...flattenPrivateRoutes(baseRoutes),
      ...flattenPrivateRoutes(guardRoutes),
      ...flattenPrivateRoutes(recoverRoutes),
      ...flattenPrivateRoutes(balanceRoutes),
      ...flattenPrivateRoutes(truRoiRoutes),
      ...flattenPrivateRoutes(reEngageRoutes),

      {
        path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills',
        element: (
          <StudioContainer
            dashboardLink={`https://datastudio.google.com/embed/reporting/77268316-60f5-49cd-961f-46d13f300425/page/FNhwC?params={"chain_parameter_id":"${currentUser?.org}"}`}
          />
        )
      },
      {
        path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills/by-brick-and-mortar',
        element: (
          <StudioContainer
            dashboardLink={`https://datastudio.google.com/embed/reporting/c4a08a5e-8b45-401c-af16-11b0f35ef950/page/p_l9git8fswc?params={"chain_parameter_id":"${currentUser?.org}"}`}
          />
        )
      },
      {
        path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills/by-state-pin',
        element: (
          <StudioContainer
            dashboardLink={`https://datastudio.google.com/embed/reporting/c4a08a5e-8b45-401c-af16-11b0f35ef950/page/p_xsbju44n1c?params={"chain_parameter_id":"${currentUser?.org}"}`}
          />
        )
      }
    ],
    [currentUser]
  )

  const googleStudioRoutes = useMemo(() => {
    return googleStudioLinks.map((link) => ({
      path: link.key,
      element: <StudioContainer dashboardLink={link.url} />
    }))
  }, [googleStudioLinks])

  const flatGlobalRoutes = useMemo(() => {
    return globalRoutes.reduce(
      (acc, route) => {
        if (route.subNav) {
          route.subNav.forEach((subRoute) => {
            if (subRoute.key) {
              if (subRoute.key[0] === '/') {
                acc.push({ key: subRoute.key, uid: route.uid })
              } else {
                const tempKey = `${route.key}/${subRoute.key}`
                const key = tempKey?.startsWith('/') ? tempKey : '/' + tempKey
                acc.push({ key, uid: route.uid })
              }
            }
          })
        } else {
          const key = route.key?.startsWith('/') ? route.key : '/' + route.key
          acc.push({ key, uid: route.uid })
        }

        return acc
      },
      [] as { key: string; uid: string }[]
    )
  }, [globalRoutes])

  const noAccess = useMemo(() => {
    if (currentUser && orgConfig) {
      try {
        const accessLevel = getAccessLevel(currentUser)
        const compareRoutes = getRoutes(accessLevel)
          .map((route) => {
            const routeUid = typeof route === 'string' ? route : route.uid
            const routeObj = globalRoutes.find((e) => e.uid === routeUid)
            return routeObj?.key
          })
          .filter((e) => !!e)

        if (location.pathname === '/' || !compareRoutes.includes(location.pathname)) {
          return false
        }
      } catch (error) {
        console.error("Error in PrivateRouter's noAccess", error)
      }
      return false
    }
    return true
  }, [currentUser, orgConfig, flatGlobalRoutes, globalRoutes, location.pathname])

  const privateRoutes = useMemo(() => {
    return [
      {
        path: '/',
        element: <PrivateLayout noAccess={noAccess} />,
        children: [
          ...googleStudioRoutes,
          ...supersetRoutes,
          ...filteredRouteList,
          { path: 'wizard/:wizardID', element: <Wizard /> },
          { index: true, element: <Navigate to={navigateLink} /> },
          { path: 'home', element: <HomePage /> },
          {
            path: 'dashboard',
            element: (
              <Navigate
                replace
                to="/home"
              />
            )
          },
          { path: 'data-refresh-status', element: <DataRefreshStatus /> },
          { path: 'owner-details', element: <OwnerDetails /> },
          { path: 'link-phone', element: <LinkAuthProvider /> },
          { path: 'sentry-error-test', element: <SentryError /> },
          { path: 'connect/slack', element: <SlackOAuth /> },
          { path: 'user/profile', element: <UserProfile /> },
          { path: 'user/management', element: <UserManagement /> },
          {
            path: 'notification-settings',
            element: <NotificationCenter />
          },
          { path: 'integrations', element: <Integrations /> },
          { path: 'onboard/uber', element: <OnboardUberEats /> },
          {
            path: 'access-denied',
            element: (
              <NotFound
                to={navigateLink}
                blockedOnDemo
              />
            )
          },
          {
            path: 'login/expired',
            element: (
              <>
                <MagicLinkExpired />
              </>
            )
          },
          {
            path: '*',
            element: <NotFound to={navigateLink} />
          }
        ]
      },
      ...generalRoutes,
      ...coreRoutes,
      {
        path: 'access-denied',
        element: (
          <NotFound
            to={navigateLink}
            blockedOnDemo
          />
        )
      },
      {
        path: '*',
        element: <NotFound to={navigateLink} />
      }
    ]
  }, [googleStudioLinks, generalRoutes, filteredRouteList])

  // =========== Helper Functions ===========
  const isRouteExist = (route: string, flatRoutes: string[]) => {
    const path = flatRoutes.find((e) => {
      const match = matchPath({ path: route, caseSensitive: false }, `/${e}`)
      return match
    })
    return !!path
  }

  // =========== Effect Hooks ===========
  useEffect(() => {
    let unsubscribe
    if (currentUser) {
      const query = doc(firebaseDb, `users/${currentUser.uid}`)

      unsubscribe = onSnapshot(query, async (snapShot) => {
        if (!snapShot.data()) logout()

        const tempUser = getSession('data')
        if (tempUser) {
          const localSessionVersion = get(tempUser, 'session_version', 0)
          const localFilterVersion = get(tempUser, 'filter_version', 0)
          const localLogoutVersion = get(tempUser, 'logout_version', 0)
          const tempCurrentUser = snapShot.data()
          setSession('data', tempCurrentUser)
          const sessionVersion = get(tempCurrentUser, 'session_version', 0)
          const filterVersion = get(tempCurrentUser, 'filter_version', 0)
          const logoutVersion = get(tempCurrentUser, 'logout_version', 0)

          if (sessionVersion.toString() !== localSessionVersion.toString()) {
            // refresh session
            const data = await fetchUserOrgAndRole(currentUser.uid)
            await getApiData(currentUser, data)
            refreshFilters(currentUser.uid, currentUser.org)
            sendSlackNotification({
              message: `SESSION REFRESHED FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `SESSION REFRESHED FROM ADMIN`
            })
          }
          if (filterVersion.toString() !== localFilterVersion.toString()) {
            // refresh filter
            refreshFilters(currentUser.uid, currentUser.org)
            sendSlackNotification({
              message: `FILTER REFRESHED FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `FILTER REFRESHED FROM ADMIN`
            })
          }
          if (logoutVersion.toString() !== localLogoutVersion.toString()) {
            // logout user
            logout()
            sendSlackNotification({
              message: `USER LOGGED OUT FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `USER LOGGED OUT FROM ADMIN`
            })
          }
        }
      })
    }

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [currentUser])

  useEffect(() => {
    setPrivateRoutes(filteredRouteList)
  }, [filteredRouteList])

  useEffect(() => {
    async function getWizardRoute() {
      const accessLevel = getAccessLevel(currentUser)
      const findAccessLevel = Object.keys(get(orgConfig, 'accessLevels', {}))
      const tempAccessLevel =
        findAccessLevel.includes(accessLevel) && !findAccessLevel.includes(AccessLevelEnum.DEMO) ? accessLevel : AccessLevelEnum.BUSINESS_ADMIN
      const wizardList = getWizards(tempAccessLevel)
      const wizardRoutes = await getWizardRoutes(wizardList)
      setWizardRoutes(wizardRoutes)
    }

    getWizardRoute()
  }, [currentUser])

  useEffect(() => {
    const getStandaloneRoutes = async () => {
      const accessLevel = getAccessLevel(currentUser)
      const findAccessLevel = Object.keys(get(orgConfig, 'accessLevels', {}))
      const tempAccessLevel =
        findAccessLevel.includes(accessLevel) && !findAccessLevel.includes(AccessLevelEnum.DEMO) ? accessLevel : AccessLevelEnum.BUSINESS_ADMIN

      const accessRoutes = getRoutes(tempAccessLevel)
      const defaultRoutes = get(orgConfig, 'navConfig', [])
      const filterRoutes = accessRoutes.length > 0 ? accessRoutes : defaultRoutes

      const navConfigKeyArr = filterRoutes.map((item) => (typeof item === 'string' ? item : item.uid))

      const standaloneRoutes = await generateStandaloneRoutes(navConfigKeyArr)
      setFilteredRouteList((prev) => {
        const set = new Set(prev)
        standaloneRoutes.forEach((route) => set.add(route))
        return Array.from(set)
      })
    }

    getStandaloneRoutes()
  }, [orgConfig, currentUser])

  useEffect(() => {
    if (!orgConfig || !currentUser || !flatRoute || !wizardRoutes) return

    const formatRoutes = () => {
      try {
        const temporaryRouteList = privateRouteList.filter(
          (item) => currentUser?.internal || item.path === '/login/expired' || isRouteExist(item.path, flatRoute)
        )

        const wizardRoutesFiltered = wizardRoutes.reduce((acc, item) => {
          const stepRoutes = item.steps
            .map((step) => privateRouteList.find((route) => matchPath({ path: `${step.key}`, caseSensitive: false }, `/${route.path}`)))
            .filter(Boolean) as RouteObject[]
          return [...acc, ...stepRoutes]
        }, [] as RouteObject[])

        setFilteredRouteList((prev) => {
          const set = new Set(prev)
          temporaryRouteList.forEach((route) => set.add(route))
          wizardRoutesFiltered.forEach((route) => set.add(route))
          return Array.from(set)
        })

        setStandaloneRoutesLoaded(true)
      } catch (error) {
        console.error('Error in PrivateRouter formatRoute', error)
      }
    }

    formatRoutes()
  }, [orgConfig, currentUser, flatRoute, wizardRoutes])

  useEffect(() => {
    if (currentUser?.email) {
      window.dataLayer = window.dataLayer || []
      const { uid, email, phoneNumber, org, 'access-level': accessLevel, name: fullName, photoURL: avatar } = currentUser

      const user = {
        id: uid,
        signup_date: Date.now() / 1000,
        userId: uid,
        fullName: capitalize(fullName),
        email,
        phoneNumber,
        company: org,
        accessLevel,
        avatar
      }

      window.dataLayer.push(user)
      window.loop = {
        user,
        meta: {
          name: APP_NAME,
          version: APP_VERSION
        }
      }

      posthog.identify(user.email, { ...window.loop.meta, ...window.loop.user })
      posthog.capture('$pageview', { ...window.loop.meta, ...window.loop.user })
      posthog.group('company', user.company, { ...window.loop.meta, ...window.loop.user })
      posthog.register({ email: user.email })

      HyperDX.setGlobalAttributes({
        userId: uid,
        userEmail: email,
        userName: fullName,
        teamName: org
      })
    }
  }, [currentUser, location.pathname, orgConfig])

  const newRoutes = useSentryRoutes(privateRoutes)

  if (currentUser && currentUser.stage === 'waiting') {
    return <>{newRoutes}</>
  }

  return <>{filteredRouteList.length > 0 ? newRoutes : <Loader loading />}</>
})

export default PrivateRouter
