import { lazy } from 'react'
import { LoopModule } from 'src/services/openApiV2'

const ReviewsAndRatings = lazy(() => import('src/pages/members/RatingAndReviewsPages/ReviewsAndRatings/ReviewsAndRatings'))
const ReviewsAndRatingsTrends = lazy(() => import('src/pages/members/RatingAndReviewsPages/Trends/Trends'))
const ReviewsAndRatingsStoreRatings = lazy(() => import('src/pages/members/RatingAndReviewsPages/StoreRatings/StoreRatings'))
const ReviewsAndRatingsReviews = lazy(() => import('src/pages/members/RatingAndReviewsPages/Reviews/Reviews'))
const ResponseConfiguration = lazy(() => import('src/pages/members/ResponseConfiguration/ResponseConfiguration'))
const Benchmarks = lazy(() => import('src/pages/members/Benchmarks/Benchmarks'))

export const existingRoutes = [
  { path: 'reviews/ratings', element: <ReviewsAndRatings /> },
  { path: 'reviews/ratings/trends', element: <ReviewsAndRatingsTrends /> },
  { path: 'reviews/ratings/store_ratings', element: <ReviewsAndRatingsStoreRatings /> },
  { path: 'reviews/ratings/reviews', element: <ReviewsAndRatingsReviews /> },
  {
    path: 're-engage/benchmarks',
    element: <Benchmarks module={LoopModule.REENG} />
  }
]

export const moduleNamedRoutes = [
  {
    path: 're-engage',
    children: [
      { index: true, element: <ReviewsAndRatingsTrends /> },
      { path: 'trends', element: <ReviewsAndRatingsTrends /> },
      { path: 'store-ratings', element: <ReviewsAndRatingsStoreRatings /> },
      { path: 'reviews', element: <ReviewsAndRatingsReviews /> },
      { path: 'response-automation', element: <ResponseConfiguration /> },
      { path: 'benchmarks', element: <Benchmarks module={LoopModule.REENG} /> }
    ]
  }
]

export const reEngageRoutes = [...existingRoutes, ...moduleNamedRoutes]
