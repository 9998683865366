import { Button, Dialog, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box } from '@mui/system'
import * as React from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import backdrop from 'src/assets/backdrop.svg'
import cross from 'src/assets/cross.svg'
import ButtonWithLoader from 'src/components/Buttons/ButtonWithLoader'
import { useAuth } from 'src/context/AuthContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { useSnackData } from 'src/context/SnackContext'
import { sendSlackNotification } from 'src/utils/api'
import { generateKey } from 'src/utils/functions'

const PreviewPage = ({ modulePageName, padding = true }: { modulePageName: string | React.ReactNode; padding?: boolean }) => {
  const { currentUser } = useAuth()
  const location = useLocation()
  const { openError } = useSnackData()
  const { categorizedRoutes } = usePrivateLayoutDrawerContext()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  // TODO: use category and route to send email
  const { category, route } = React.useMemo(() => {
    const pathname = location.pathname
    const { category = null, route = null } = (() => {
      for (let i = 0; i < categorizedRoutes.length; i++) {
        const category = categorizedRoutes[i]
        const route = category.routes.find((route) => {
          if (route?.subNav?.length > 0) {
            return route.subNav.some((subNav) => {
              return generateKey(route.key, subNav.key) === pathname
            })
          } else {
            return generateKey(route.key) === pathname
          }
        })
        if (route) {
          return { category: category.category, route }
        }
      }
      return {}
    })()
    return { category, route }
  }, [categorizedRoutes, location])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getModulePageName = () => {
    return typeof modulePageName === 'string' ? (modulePageName.split('||').length > 1 ? modulePageName.split('||')[1] : modulePageName) : modulePageName
  }

  const sendUnlockEmail = async () => {
    try {
      if (!category || !category.uid || !route) {
        openError("Couldn't send email. Please contact support team.")
        sendSlackNotification({
          channel: 'module-request',
          message: 'Error in sending unlock module email',
          title: `User \`${currentUser.email}\` in org \`${currentUser.org}\` tried to unlock module \`${modulePageName}\` but category or route is not found. Please check the code.`
        })
        return
      }
      // await DefaultService.sendUnlockModuleEmailEmailUnlockModulePost({ moduleUid: category.uid, routeName: route.label })
      handleClickOpen()
      sendSlackNotification({
        title: ` ${currentUser.email} in org \`${currentUser.org}\` requested access to \`${modulePageName}\` module`,
        message: `User requested access to \`${modulePageName}\` module`,
        channel: 'module-request',
        test: false
      })
      setSubmitted(true)
    } catch (err) {
      openError('Something went wrong. Please contact support team.')
      sendSlackNotification({
        channel: 'module-request',
        message: 'Unlock Email Error',
        title: `Error in Unlock module notification for user \`${currentUser.email}\` in org \`${currentUser.org}\` Error details: ${err.message}`
      })
    }
  }

  return (
    <>
      <Dialog
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'white', // Setting the backdrop color to white
              opacity: 0.9 // Adjust opacity as needed
            }
          }
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <>
            Request Submitted Successfully
            <Box
              onClick={handleClose}
              sx={{
                p: 2,
                ':hover': {
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  transition: '0.3s'
                },
                height: '26px',
                display: 'flex',
                backgroundImage: `url(${cross})`,
                backgroundPosition: 'center',
                width: '26px',
                justifyContent: 'center',

                alignItems: 'center',
                position: 'absolute',
                right: 16,
                top: 16,
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer'
              }}
            />
          </>
        </DialogTitle>
        <DialogContent
          sx={{
            position: 'relative',
            pb: 1
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%'
            }}>
            <Box
              sx={{
                height: '160px',
                display: 'flex',
                backgroundImage: `url(${backdrop})`,
                backgroundPosition: 'top',
                width: '100%',
                justifyContent: 'center',

                alignItems: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            />
            <DialogContentText id="alert-dialog-description">
              <Typography
                fontSize={14}
                fontWeight={'bold'}
                mb={2}>
                We're preparing to set up your {getModulePageName()} module.
              </Typography>
              <Typography
                fontSize={14}
                sx={{
                  color: 'grey.800',
                  fontWeight: '500',
                  wordWrap: 'break-word',
                  display: 'inline'
                }}>
                Our customer success team will be in touch soon. Thank you for your interest and patience!
              </Typography>
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            p: 2
          }}>
          <Button
            fullWidth
            onClick={handleClose}
            autoFocus>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          width: '100%',
          px: padding ? 4 : 0,
          pt: padding ? 3 : 0
        }}>
        <img
          src={backdrop}
          alt="backdrop"
          style={{ width: '100%' }}
        />
        <Typography
          variant="h1"
          sx={{
            width: 624.24,
            color: 'grey.600',
            fontSize: 32,
            fontWeight: '600',
            wordWrap: 'break-word'
          }}>
          To unlock the{' '}
          <Box
            sx={{
              color: 'black',
              display: 'inline'
            }}>
            {getModulePageName()}
          </Box>{' '}
          please contact support team.
        </Typography>

        <ButtonWithLoader
          variant="contained"
          loading={loading}
          buttonText={submitted ? 'Request Submitted' : 'Contact Support'}
          sx={{ width: 286, height: 60, background: '#196E82', borderRadius: 1 }}
          onClick={() => {
            setLoading(true)
            sendUnlockEmail().then(() => {
              setLoading(false)
            })
          }}
          disabled={submitted}
        />
      </Box>
    </>
  )
}

export default PreviewPage
