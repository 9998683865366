import React, { Suspense, useEffect } from 'react'

import { useLocation, useSearchParams } from 'react-router-dom'
import RedirectionDialog from 'src/components/Dialogs/RedirectionDialog/RedirectionDialog'
import CustomDialog from 'src/components/mui/CustomDialog'
import Loading from 'src/components/mui/Loader'
import DrillDownContextProvider from 'src/context/DrillDownContext'
import GlobalDialogContextProvider from 'src/context/GlobalDialogContext'
import NewFilterContextProvider from 'src/context/NewFilterContext/NewFilterContext'
import PrivateLayoutDrawerContextProvider from 'src/context/PrivateLayoutDrawerContext'
import { usePublicAuthContext } from 'src/context/PublicAuthContext'
import StoreDetailContextProvider from 'src/context/StoreDetailContext'
import SubChainContextProvider from 'src/context/SubChainContext'
import TopbarContextProvider from 'src/context/TopbarContext'
import WizardContextProvider from 'src/context/WizardContext'
import { FluxChatProvider } from 'src/pages/members/Finance/flux/context/FluxChatContext'
import PrivateRouter from 'src/routes/PrivateRouter'
import AuthContextProvider from '../context/AuthContext'

const PublicRouter = React.lazy(() => import('src/routes/PublicRouter'))

const Router: React.FC = React.memo(() => {
  const { currentUser, pageDetails, setPageDetails, loading } = usePublicAuthContext()
  const location = useLocation()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    if (pageDetails?.path !== location.pathname) {
      params.delete('section')
      setParams(params)
      const currentTime = new Date().getTime()
      setPageDetails({ path: location.pathname, visitTime: currentTime })
    }
  }, [location])

  useEffect(() => {
    const url = new URL(window.location.href)
    if (url.searchParams.has('referred_by')) {
      url.searchParams.delete('referred_by')
      window.history.replaceState('', '', url.toString())
    }
  }, [window.location.href])

  // if (!navigator.onLine) {
  //   return (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <NoInternetPage />
  //     </Suspense>
  //   )
  // }

  return (
    <GlobalDialogContextProvider>
      <RedirectionDialog />
      <CustomDialog />
      {loading && <Loading loading />}
      {!loading && !currentUser && (
        <Suspense fallback={<Loading loading />}>
          <PublicRouter />
        </Suspense>
      )}
      {!!currentUser && (
        <Suspense fallback={<Loading loading />}>
          <AuthContextProvider>
            <SubChainContextProvider>
              <DrillDownContextProvider>
                <NewFilterContextProvider>
                  <StoreDetailContextProvider>
                    <PrivateLayoutDrawerContextProvider>
                      <WizardContextProvider>
                        <TopbarContextProvider>
                          <FluxChatProvider>
                            <PrivateRouter />
                          </FluxChatProvider>
                        </TopbarContextProvider>
                      </WizardContextProvider>
                    </PrivateLayoutDrawerContextProvider>
                  </StoreDetailContextProvider>
                </NewFilterContextProvider>
              </DrillDownContextProvider>
            </SubChainContextProvider>
          </AuthContextProvider>
        </Suspense>
      )}
    </GlobalDialogContextProvider>
  )
})

export default Router
