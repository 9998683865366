import * as Sentry from '@sentry/browser'
import { signInWithCustomToken } from 'firebase/auth'
import { collection, deleteField, doc, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore'
import posthog from 'posthog-js'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useErrorData } from 'src/context/ErrorContext'
import { db, fetchFirebaseData, setAllAccessLevels } from 'src/services/firebase'

import { get, isEqual } from 'src/utils/config/lodashUtils'

import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { getSession, setSession } from 'src/utils/functions/localStorage'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import { auth, clearList, fetchGoogleStudioLinks, fetchOrgConfig, fetchSupersetLinks } from '../services/firebase'

import { useSnackData } from './SnackContext'

import { DefaultService, OpenAPI } from 'src/services/openApiV2'
import { sendSlackNotification } from 'src/utils/api'
import { BASE_FRONTEND_URL, LOOPAIXYZ_DOMAIN, LOOPKITCHENXYZ_DOMAIN, TRYLOOPAI_DOMAIN } from 'src/utils/config/config'
import { getSigninToken } from 'src/utils/firebaseFunctions/authFunctions'
import { isInternalUser } from 'src/utils/functions'
import { decrypt, encrypt } from 'src/utils/functions/encryptionDecryption'
import {
  AccessLevelEnum,
  AccessLevelType,
  AuthContextInterface,
  OrgConfigType,
  RedirectDialogType,
  RouteType,
  SupersetLinkType,
  User,
  UserType
} from './AuthContext.type'
import { RouteCategoryType } from './PrivateLayoutDrawerContext'
import { usePublicAuthContext } from './PublicAuthContext'
import { SubChainModules, SubChainOrgUser, getSubchainOrgUsers } from './SubChainContext'

const initialState = {} as AuthContextInterface

export const AuthContext = createContext<AuthContextInterface>(initialState)

export const useAuth = (): AuthContextInterface => useContext(AuthContext)

const AuthContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const { currentUser: publicCurrentUser, authCurrentUser, analyticsReset, logout: publicLogout, setLoading } = usePublicAuthContext()
  const { openInfo, openError } = useSnackData()
  const [currentUser, setCurrentUser] = useState<User>(publicCurrentUser)
  const [hasMagicLink, setHasMagicLink] = useState(false)
  const { handleError, asyncWrapper } = useErrorData()
  const [orgConfig, setOrgConfig] = useState({} as OrgConfigType)
  const [orgConfigLoading, setOrgConfigLoading] = useState(true)
  const [accessLevels, setAccessLevels] = useState<AccessLevelType[]>([])
  const [userList, setUserList] = useState<UserType[]>([])
  const [userListLoading, setUserListLoading] = useState(true)

  const [defaultRoute, setDefaultRoute] = useState('dashboard')
  const [googleStudioLinks, setGoogleStudioLinks] = useState([])
  const [supersetLinks, setSupersetLinks] = useState<SupersetLinkType[]>([])
  const [standaloneRoutesLoaded, setStandaloneRoutesLoaded] = useState(false)
  const [globalRoutes, setGlobalRoutes] = useState<RouteType[]>([])
  const [globalRoutesLoading, setGlobalRoutesLoading] = useState(true)
  const isDemo = get(currentUser, 'access-level', []).includes(AccessLevelEnum.DEMO) || false
  const [redirectLoading, setRedirectLoading] = useState(false)
  const [isCurrentUserSet, setIsCurrentUserSet] = useState(false)
  const [subChainData, setSubchainData] = useState<SubChainOrgUser>(null)
  const [subchainModulesList, setSubchainModulesList] = useState<SubChainModules[]>([])
  const [routeCategories, setRouteCategories] = useState<RouteCategoryType[]>([])
  const [categoryLoading, setCategoryLoading] = useState(true)
  const [categoryOrder, setCategoryOrder] = useState([])
  const [openRedirectionDialog, setOpenRedirectionDialog] = useState<RedirectDialogType>({
    openRedirection: false,
    redirectionCallback: () => Promise.resolve(),
    openRedirected: false,
    redirectedCallback: () => Promise.resolve()
  })

  const isOrgInActive = useMemo(() => {
    return orgConfig?.is_active?.toLowerCase() === 'inactive'
  }, [orgConfig?.is_active])

  const subchainModules = useMemo(() => {
    if (!currentUser) return null
    const { uid } = currentUser
    if (subChainData && Array.isArray(subChainData[uid]) && subChainData[uid].length > 0) {
      const { name } = subChainData[uid][0]
      const moduleItem = subchainModulesList.find((item) => item?.name === name)
      if (typeof moduleItem === 'object' && Array.isArray(moduleItem?.modules)) {
        const { modules } = moduleItem
        return modules
      }
    } else {
      return null
    }
  }, [subChainData, currentUser, subchainModulesList])

  const getSubChainRoutes = useCallback(
    (accessLevel: string) => {
      if (
        ![AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.BUSINESS_MANAGER].includes(accessLevel as AccessLevelEnum) &&
        Array.isArray(subchainModules) &&
        subchainModules.length > 0
      ) {
        const defaultAccessLevelConfig = accessLevels.find((item) => item.uid === accessLevel)
        const defaultRoutes: typeof defaultAccessLevelConfig.routes = get(defaultAccessLevelConfig, 'routes', []) || []

        const unCategorizedDefaultRoutes = defaultRoutes.filter((uid) => {
          return routeCategories.every((e) => !e.routes.includes(uid))
        })

        const defaultModules: typeof defaultAccessLevelConfig.modules = get(defaultAccessLevelConfig, 'modules', []) || []
        const defaultModuleRoutes = routeCategories
          .filter((e) => defaultModules.some((uid) => e.uid === uid))
          .reduce(
            (acc, curr) => {
              acc.push(...curr.routes)
              return acc
            },
            [] as typeof defaultRoutes
          )
          .concat(unCategorizedDefaultRoutes)

        const routes = orgConfig.routeDisplayType === 'modules' ? defaultModuleRoutes : defaultRoutes

        const userIsFinanceTeam = accessLevel === AccessLevelEnum.FINANCE_TEAM
        const subchainRoutes = subchainModules
          .map((module) => {
            return routeCategories
              .map((item) => (item.uid === module.uid ? item.routes : []))
              .flat()
              .filter((route) => (userIsFinanceTeam ? true : routes.includes(route)))
              .map((route) => {
                return {
                  uid: route,
                  type: module.type
                }
              })
          })
          .flat()
        return subchainRoutes
      }
      return []
    },
    [subchainModules, routeCategories, accessLevels, orgConfig]
  )

  const getRoutes = useCallback(
    (paramAccessLevel?: AccessLevelEnum) => {
      if (!orgConfig) return []
      const accessLevel = paramAccessLevel || getAccessLevel(currentUser)
      const routes = (() => {
        const subChainRoutes = getSubChainRoutes(accessLevel)
        if (Array.isArray(subChainRoutes) && subChainRoutes.length > 0) {
          return subChainRoutes
        }
        const routes = get(orgConfig, `access_levels.${accessLevel}.routes`, [])
        const modules: (string | { uid: string; type: 'preview' | 'live' })[] = get(orgConfig, `access_levels.${accessLevel}.modules`, [])

        const unCategorizedRoutes = routes
          .map((item) => (typeof item === 'string' ? item : item.uid))
          .filter((routeUid) => {
            return !routeCategories.some((category) => category.routes.includes(routeUid))
          })

        const moduleRoutes = modules
          .map((item) => {
            const moduleUid = typeof item === 'string' ? item : item.uid
            const moduleType = typeof item === 'string' ? 'live' : item.type
            const category = routeCategories.find((category) => category.uid === moduleUid)
            return category?.routes.map((route) => ({ uid: route, type: moduleType })) || []
          })
          .flat()
          .concat(unCategorizedRoutes.map((route) => ({ uid: route, type: 'live' })) as { uid: string; type: 'live' }[])

        const routeDisplayType = orgConfig && orgConfig?.routeDisplayType === 'modules' ? 'modules' : 'routes'

        if (routeDisplayType === 'modules') {
          return moduleRoutes
        }
        return routes
      })()

      const filteredRoutes = routes.filter((item) => {
        // This check is for org specific routes
        const routeUid = typeof item === 'string' ? item : item.uid
        const routeObj = globalRoutes.find((route) => route.uid === routeUid)
        const hasOrgs = Array.isArray(routeObj?.orgs) && routeObj.orgs.length > 0
        if (hasOrgs) {
          if (routeObj?.orgs_action === 'exclude') {
            return !routeObj.orgs.includes(currentUser.org)
          }
          return routeObj.orgs.includes(currentUser.org)
        }
        return true
      })

      return filteredRoutes
    },
    [currentUser, orgConfig, routeCategories, globalRoutes, getSubChainRoutes]
  )

  const getWizards = useCallback(
    (paramAccessLevel?: AccessLevelEnum) => {
      if (!orgConfig) return [] as string[]
      const accessLevel = paramAccessLevel || getAccessLevel(currentUser)
      return get(orgConfig, `access_levels.${accessLevel}.wizards`, []) as string[]
    },
    [currentUser, orgConfig]
  )

  function changeStatus(id: string) {
    const userRef = doc(db, 'users', id)
    updateDoc(userRef, { status: deleteField() })
  }

  const getGoogleDataStudioLinks = async () => {
    try {
      const cachedStudioLinks = getSession('studio_links')
      const studioData = cachedStudioLinks ?? (await fetchGoogleStudioLinks())

      setSession('studio_links', studioData)
      setGoogleStudioLinks(studioData)
    } catch (err) {
      handleError('Error in fetching google studio links')
    }
  }

  const getAllAccessLevels = async () => {
    try {
      await setAllAccessLevels()
    } catch (err) {
      console.error('Error in fetching all access levels: ', err)
    }
  }

  const getSupersetLinks = async () => {
    try {
      const cachedSupersetLinks = getSession('superset_links')
      const res = cachedSupersetLinks ?? (await fetchSupersetLinks())
      setSession('superset_links', res)
      setSupersetLinks(res)
    } catch (err) {
      handleError('Error in fetching superset links')
    }
  }

  const getApiData = async (currUser, data) => {
    try {
      if (!data || !data?.org) {
        throw new Error("Cannot read properties of null (reading 'org')")
      }
      // setLoading(true)
      if (!isEqual(currentUser, { ...currentUser, ...data })) {
        setCurrentUser({
          ...currentUser,
          ...data
        })
      }
      setIsCurrentUserSet(true)
      changeStatus(currUser.uid)

      const cachedOrgConfig = getSession('org_config')
      const cachedDefaultOrgConfig = getSession('default_org_config')
      const cachedRouteCategories = getSession('route_categories')

      let result: any = await Promise.allSettled([
        cachedOrgConfig ?? fetchOrgConfig(data.org, currUser['access-level']),
        cachedDefaultOrgConfig ?? fetchOrgConfig('default', currUser['access-level']),
        cachedRouteCategories ?? fetchFirebaseData('route_categories')
      ]).catch((error) => {
        console.log(error.message)
        if (handleError) handleError(error.message)
      })
      result = result.map((item) => item.value)

      if (result[0]) {
        const access_levels = get(result, '[0].access_levels', {})
        const accessLevel = Array.isArray(data['access-level']) ? data['access-level'][0] : data['access-level']
        const defaultRouteForRole = get(access_levels, `${accessLevel}.defaultRoute.[0]`, get(access_levels, `${accessLevel}.routes.[0]`, 'dashboard'))
        if (defaultRouteForRole) setDefaultRoute(defaultRouteForRole)
        let navConfig = get(result, `[0].access_levels.[${accessLevel}].routes`, [])
        const logo = get(result, `[0].logo`, undefined)
        let filterConfig = result[0]?.filterConfig
        if (!navConfig.length) {
          navConfig = result[1]?.navConfig
        }
        if (!filterConfig) {
          filterConfig = result[1]?.filterConfig
        }
        const routeCategories = result[2] || []
        setRouteCategories(routeCategories)
        const orgData = get(result, '[0]', {})
        const orgConfigData = {
          ...orgData,
          navConfig,
          filterConfig,
          access_levels,
          logo
        }
        setOrgConfig((prev: any) => ({ ...prev, ...orgConfigData }))
        setOrgConfigLoading(false)
        setSession('org_config', orgConfigData)
        localStorage.setItem('storageDate', new Date().toDateString())

        //set data to localstorage
        setSession('data', data)
        setSession('userOrg', data.org)
      } else {
        params.append('error', 'Sorry, the organization name you entered is incorrect. Please check spelling and capitalization and try again.')
        setParams(params)
        logout()
      }
    } catch (error) {
      console.log(error.message)
      if (handleError) handleError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getHasMagicLink = async () => {
    try {
      const result = (await DefaultService.hasMagicLinkUserHasAdminMagicLinkGet()) as { has_magic_link: boolean }
      setHasMagicLink(!!result?.has_magic_link)
      return !!result?.has_magic_link
    } catch (err) {
      console.error('Error in getting has magic link: ', err)
      return false
    }
  }

  useEffect(() => {
    if (isOrgInActive) {
      navigate('/create-account/wait', { replace: true })
      if (!isInternalUser(publicCurrentUser)) {
        sendSlackNotification({
          channel: 'inactive-chain-actions',
          message: `User \`${currentUser.email}\` is trying to login to inactive chain (\`${currentUser.org}\`)`,
          title: `User email: \`${currentUser.email}\`\nOrg: \`${currentUser.org}\`\nAccess Level: \`${getAccessLevel(currentUser)}\`\nUser is trying to login to inactive chain\nFrom: \`${window.location.href}\``
        })
      }
    }
  }, [isOrgInActive, isInternalUser(publicCurrentUser)])

  useEffect(() => {
    const getUserData = async () => {
      try {
        const condition = Boolean(authCurrentUser)

        if (condition) {
          // checking if email is present in the url
          const regex = new RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}')
          if (regex.test(window.location.pathname)) {
            navigate('/')
          }
          Sentry.setUser({ email: authCurrentUser.email })
          OpenAPI.TOKEN = async () => {
            try {
              const token = await authCurrentUser.getIdToken()
              setSession('authToken', token, true)
              return token
            } catch (err) {
              console.log('error in getting token: ', err)
              return null
            }
          }
          const data = publicCurrentUser

          // Handling observer access level
          if (getAccessLevel({ ...authCurrentUser, ...data }) === AccessLevelEnum.OBSERVER) {
            setLoading(false)
            logout()
            return openError('Login access denied')
          }

          // Handling email verification
          if (data) {
            if (authCurrentUser && !authCurrentUser.emailVerified) {
              setLoading(false)
              logout()
              return openError('Please verify your email before login')
            }
            const cachedHasMagicLink = getSession('has_magic_link')
            const userHasMagicLink = cachedHasMagicLink === undefined || cachedHasMagicLink === null ? await getHasMagicLink() : cachedHasMagicLink
            setLoading(false)
            if (data.stage === 'waiting' && !userHasMagicLink) {
              const waitingLink = '/create-account/wait'
              setCurrentUser({
                ...authCurrentUser,
                ...data
              })
              setIsCurrentUserSet(true)
              changeStatus(authCurrentUser.uid)
              if (location.pathname !== waitingLink) {
                navigate(waitingLink)
              }
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              return
            }

            if (data && data?.org) {
              await Promise.all([getGoogleDataStudioLinks(), getAllAccessLevels(), getSupersetLinks(), getGlobalRoutes()])

              await getApiData(authCurrentUser, data)
              if (location.pathname === '/create-account/wait') navigate('/')
              setLoading(false)
            } else {
              handleError("Organization information not found. Please contact your organization's admin.")
              setTimeout(() => {
                navigate('/')
                logout()
              })
            }
          } else {
            //create new user entry
            if (authCurrentUser) {
              const newUserObj = {
                name: authCurrentUser.displayName,
                email: authCurrentUser?.email,
                org: null,
                phoneNumber: authCurrentUser.phoneNumber,
                uid: authCurrentUser.uid,
                access_names: [],
                'access-level': [AccessLevelEnum.BUSINESS_ADMIN],
                stage: 'waiting'
              }
              setCurrentUser({
                ...newUserObj,
                stage: 'waiting'
              })
              setIsCurrentUserSet(true)
              navigate('/create-account/wait')
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              await setDoc(doc(db, 'users', authCurrentUser.uid), newUserObj)
              changeStatus(authCurrentUser.uid)
              setLoading(false)
            }
          }

          setRedirectLoading(false)
        } else {
          clearList()
          setCurrentUser(undefined)
          setIsCurrentUserSet(true)
          setLoading(false)
          logout()
        }
      } catch (error) {
        setLoading(false)
        if (handleError) {
          if (error.message.includes("Cannot read properties of null (reading 'org')")) {
            handleError('You are not registered with us')
            logout()
          } else {
            handleError(error.message)
          }
        } else {
          console.log(error.message)
        }
      }
    }
    getUserData()
  }, [authCurrentUser])

  useEffect(() => {
    let unsubscribeSpecificOrg: any
    let unsubscribeAccessLevels: any
    let unsubscribeUserList: any
    let unsubscribeSubchainModules: any
    let unsubscribeGoogleStudio: any
    let unsubscribeSuperset: any
    let unsubscribeRoutes: any
    let unsubscribeCurrentUser: any
    let unsubscribeOrgConfig: any
    let unsubscribeDefaultOrgConfig: any
    let unsubscribeHasMagicLink: any

    if (currentUser && currentUser.org) {
      const accessLevel = getAccessLevel(currentUser)
      if (accessLevel === AccessLevelEnum.OBSERVER) {
        logout()
        openError('Login access denied')
      }
      try {
        const q = doc(db, `org_config_2/${currentUser.org}`)
        unsubscribeSpecificOrg = onSnapshot(q, (snapShot) => {
          const tempOrgConfig = getSession('org_config')
          if (tempOrgConfig) {
            const version1 = get(tempOrgConfig, 'version', 0)
            const data = snapShot.data()
            const version2 = get(data, 'version', 0)
            setOrgConfig((prev: any) => ({ ...prev, ...data }))
            setOrgConfigLoading(false)

            setSession('org_config', data)
            if (version2.toString() !== version1.toString()) {
              openInfo('Your access level is updated by your organization')
            }
          }
        })
      } catch (error) {
        console.log(error.message)
      }

      try {
        const q = collection(db, 'access_levels')
        unsubscribeAccessLevels = onSnapshot(q, (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            return { ...data, uid: doc.id, label: data.name, value: doc.id }
          })
          // @ts-ignore
          setAccessLevels(documents)

          const docs = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            return {
              name: data?.name,
              label: data?.label,
              is_custom: data?.is_custom
            }
          })
          setSession('allAccessLevels', docs)
        })
      } catch (err) {
        console.log('access level error: ', err)
      }

      try {
        if (shouldBlockForUser(currentUser)) {
          setUserList([])
        } else {
          const docRef = query(collection(db, 'users'), where('org', '==', currentUser.org))

          unsubscribeUserList = ![AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.BUSINESS_MANAGER, AccessLevelEnum.INTERNAL_OPERATOR].includes(
            getAccessLevel(currentUser)
          )
            ? null
            : onSnapshot(docRef, (querySnapshot) => {
                setUserListLoading(true)
                // @ts-ignore
                const documents: UserType[] = querySnapshot.docs.map((doc) => {
                  const data = doc.data()
                  return { ...data, access_level: data['access-level'], uid: doc.id }
                })
                const result = documents.filter((item) => {
                  return item.org === currentUser.org
                })
                setUserList(result)
                setUserListLoading(false)
              })
        }
      } catch (err) {
        console.log('user list error: ', err)
      }

      try {
        const q = query(collection(db, 'subchain_modules'), where('org', '==', currentUser.org))
        unsubscribeSubchainModules = onSnapshot(q, (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            return { ...data, uid: doc.id } as SubChainModules
          })
          setSubchainModulesList(documents)
        })
      } catch (err) {
        console.error('error in fetching subchain modules', err)
      }

      try {
        unsubscribeDefaultOrgConfig = onSnapshot(doc(db, 'org_config_2', 'default'), (snapshot) => {
          const data = snapshot.data()
          setSession('default_org_config', data)
        })
      } catch (err) {
        console.error('error in fetching default org config', err)
      }

      try {
        unsubscribeCurrentUser = onSnapshot(doc(db, 'users', currentUser.uid), (snapshot) => {
          const data = snapshot.data()
          if (!isEqual(data, currentUser)) {
            // @ts-ignore
            setCurrentUser(data)
            // setSession('data', data)
          }
        })
      } catch (err) {
        console.error('error in fetching user data', err)
      }

      try {
        unsubscribeGoogleStudio = onSnapshot(collection(db, 'google_studios_links'), (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data())
          setGoogleStudioLinks(data)
          setSession('google_studio_links', data)
        })
      } catch (err) {
        console.error('error in fetching google studio links', err)
      }

      try {
        unsubscribeSuperset = onSnapshot(collection(db, 'superset_links'), (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data())
          setSupersetLinks(data as SupersetLinkType[])
          setSession('superset_links', data)
        })
      } catch (err) {
        console.error('error in fetching superset links', err)
      }

      try {
        unsubscribeRoutes = onSnapshot(collection(db, 'route_categories'), (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data())
          // @ts-ignore
          setRouteCategories(data)
          setSession('route_categories', data)
        })
      } catch (err) {
        console.error('error in fetching route categories', err)
      }

      try {
        const magic_link_query = query(collection(db, 'users_magic_link'), where('user_uid', '==', currentUser.uid))
        unsubscribeHasMagicLink = onSnapshot(magic_link_query, (snapshot) => {
          const docs = snapshot.docs
          const data = Array.isArray(docs) && docs.length > 0 ? docs[0].data() : null
          if (data && get(data, 'forAdmin', false) && get(data, 'magic_link.token', '')) {
            setHasMagicLink(true)
            setSession('has_magic_link', true)
          } else {
            setHasMagicLink(false)
            setSession('has_magic_link', false)
          }
        })
      } catch (err) {
        console.error('error in fetching magic link', err)
      }
    }
    return () => {
      if (unsubscribeSpecificOrg) {
        unsubscribeSpecificOrg()
      }
      if (unsubscribeAccessLevels) {
        unsubscribeAccessLevels()
      }
      if (unsubscribeUserList) {
        unsubscribeUserList()
      }
      if (unsubscribeSubchainModules) {
        unsubscribeSubchainModules()
      }
      if (unsubscribeGoogleStudio) unsubscribeGoogleStudio()
      if (unsubscribeSuperset) unsubscribeSuperset()
      if (unsubscribeRoutes) unsubscribeRoutes()
      if (unsubscribeCurrentUser) unsubscribeCurrentUser()
      if (unsubscribeOrgConfig) unsubscribeOrgConfig()
      if (unsubscribeDefaultOrgConfig) unsubscribeDefaultOrgConfig()
      if (unsubscribeHasMagicLink) unsubscribeHasMagicLink()
    }
  }, [currentUser])

  const refreshSubChainData = useCallback(async () => {
    try {
      if (currentUser && currentUser?.org) {
        const res = await getSubchainOrgUsers(currentUser.org)
        setSubchainData(res)
      }
    } catch (err) {
      console.log('error in refreshing franchisee group data', err)
      handleError('Something went wrong while refreshing franchisee group data')
    }
  }, [currentUser?.org])

  useEffect(() => {
    if (currentUser && currentUser?.org) {
      refreshSubChainData()
    }
  }, [currentUser?.org, refreshSubChainData])

  function posthogCapture(label, data = {}) {
    try {
      posthog.capture(label, {
        email: currentUser?.email,
        name: currentUser?.name,
        org: currentUser?.org,
        time: new Date().toISOString(),
        ...data
      })
    } catch (error) {
      handleError(error.message)
    }
  }

  const getGlobalRoutes = async () => {
    try {
      const cachedGlobalRoutes = getSession('global_routes')
      if (cachedGlobalRoutes) {
        setGlobalRoutes(cachedGlobalRoutes)
        setGlobalRoutesLoading(false)
        return
      }
      const snapshotData = await fetchFirebaseData('routes')
      const tempRoutes =
        snapshotData?.map(
          (data) =>
            ({
              ...data,
              uid: data.id
            }) as RouteType
        ) || []
      setGlobalRoutes(tempRoutes)
      setSession('global_routes', tempRoutes)
      setGlobalRoutesLoading(false)
    } catch (err) {
      console.error('global routes error: ', err)
    }
  }

  useEffect(() => {
    let unsubscribeGlobalRoutes: any

    try {
      unsubscribeGlobalRoutes = onSnapshot(collection(db, 'routes'), (snapshot) => {
        const tempRoutes = snapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              uid: doc.id
            }) as RouteType
        )

        setGlobalRoutes(tempRoutes)
        setSession('global_routes', tempRoutes)
        setGlobalRoutesLoading(false)
      })
    } catch (err) {
      console.error('Error in fetching global routes ', err)
      setGlobalRoutes([])
      setGlobalRoutesLoading(false)
    }

    return () => {
      if (unsubscribeGlobalRoutes) {
        unsubscribeGlobalRoutes()
      }
    }
  }, [])

  useEffect(() => {
    const getRouteCategories = async () => {
      try {
        const routeCategoryOrderSnapshot = await fetchFirebaseData('configs', 'route_categories')
        const categoryOrder = routeCategoryOrderSnapshot?.order || []
        setCategoryOrder(categoryOrder)
        setCategoryLoading(false)
      } catch (err) {
        console.error('Error in getRouteCategories', err)
      }
    }
    getRouteCategories()
    let routeCategoryOrder = false
    const setCategoryLoadingFalse = (obj: { type: 'route_category_order' }) => {
      if (obj.type === 'route_category_order') {
        routeCategoryOrder = true
      }
      if (routeCategoryOrder) {
        setCategoryLoading(false)
      }
    }

    const unsubscribeCategoryOrder = onSnapshot(doc(db, 'configs/route_categories'), (snapshot) => {
      // setCategoryLoading(true)
      const categories = snapshot.data()?.order || []
      setCategoryOrder(categories)
      setCategoryLoadingFalse({ type: 'route_category_order' })
    })

    return () => {
      unsubscribeCategoryOrder()
    }
  }, [])

  const logout = () => {
    asyncWrapper(
      (async () => {
        posthogCapture('User logged out')
        publicLogout()
      })()
    )
  }

  // fetch initial cache from local storage
  useEffect(() => {
    const cachedUserData = getSession('data')
    if (cachedUserData !== undefined && cachedUserData != null) setCurrentUser(cachedUserData)

    const cachedLinks = getSession('google_studio_links')
    if (cachedLinks !== undefined && cachedLinks != null) setGoogleStudioLinks(cachedLinks)

    const cachedSupersetLinks = getSession('superset_links')
    if (cachedSupersetLinks !== undefined && cachedSupersetLinks != null) setSupersetLinks(cachedSupersetLinks)

    const cachedRoutes = getSession('route_categories')
    if (cachedRoutes !== undefined && cachedRoutes != null) setRouteCategories(cachedRoutes)

    const cachedOrgConfig = getSession('org_config')
    if (cachedOrgConfig !== undefined && cachedOrgConfig != null) setOrgConfig(cachedOrgConfig)

    const cachedAccessLevels = getSession('allAccessLevels')
    if (cachedAccessLevels !== undefined && cachedAccessLevels != null) setAccessLevels(cachedAccessLevels)

    const cachedHasMagicLink = getSession('has_magic_link')
    if (cachedHasMagicLink !== undefined && cachedHasMagicLink != null) setHasMagicLink(cachedHasMagicLink)
  }, [])

  const signInWithIdToken = async (idToken: string) => {
    const signInData = await getSigninToken({ idToken })

    if (signInData && signInData.success) {
      const customToken = signInData.token
      await signInWithCustomToken(auth, customToken)
    } else {
      openError('Failed to create session')
    }
  }

  const redirectUsersToTryloop = async () => {
    try {
      const location = window.location.href
      const host = window.location.host
      const isTryloopDomain = host.includes(TRYLOOPAI_DOMAIN)
      const isLoopkitchenDomain = host.includes(LOOPKITCHENXYZ_DOMAIN) || host.includes(LOOPAIXYZ_DOMAIN)
      const shortLocation = location.slice(10)
      const path = shortLocation.slice(shortLocation.indexOf('/'))

      if (isTryloopDomain) {
        const redirected = params.get('redirected') || false

        if (redirected) {
          setParams((prev) => {
            prev.delete('redirected')
            return prev
          })

          setOpenRedirectionDialog((prev) => ({
            ...prev,
            openRedirected: true,
            redirectedCallback: async () => {
              const encryptedIdToken = params.get('id_token')
              const name = get(currentUser, 'name', '-')
              const email = get(currentUser, 'email', '-')
              const org = get(currentUser, 'org', '-')

              if (encryptedIdToken) {
                const idToken = decrypt(encryptedIdToken)

                if (idToken) {
                  await sendSlackNotification({
                    message: `\`Redirected\` user to Tryloop domain`,
                    title: `Details: \n\t Logged in: \`${!!name}\` \n\t Name: \`${name}\` \n\t Email: \`${email}\` \n\t Org: \`${org}\` \n\t Id Token: \`${idToken}\``,
                    channel: 'fe-auth-redirect-logs'
                  })

                  posthogCapture('User redirected to Tryloop domain', {
                    name,
                    email,
                    org,
                    idToken
                  })

                  setRedirectLoading(true)
                  await signInWithIdToken(idToken)
                }
              } else {
                await sendSlackNotification({
                  message: `\`Redirected\` user to Tryloop domain`,
                  title: `Details: \n\t Logged in: \`${!!name}\` \n\t Name: \`${name}\` \n\t Email: \`${email}\` \n\t Org: \`${org}\` \n\t Id Token: \`-\``,
                  channel: 'fe-auth-redirect-logs'
                })

                posthogCapture('User redirected to Tryloop domain', {
                  name,
                  email,
                  org
                })
              }
            }
          }))
        }
      } else {
        if (isLoopkitchenDomain) {
          if (!isCurrentUserSet) return

          setOpenRedirectionDialog((prev) => ({
            ...prev,
            openRedirection: true,
            redirectionCallback: async () => {
              setRedirectLoading(true)

              if (currentUser) {
                const idToken = await auth.currentUser.getIdToken()
                const encryptedIdToken = encrypt(idToken)

                const redirectUrl = `${BASE_FRONTEND_URL}/?redirect=${path}&id_token=${encryptedIdToken}&redirected=true`

                await sendSlackNotification({
                  message: `\`Redirecting user\` to Tryloop domain`,
                  title: `Details: \n\t Logged in: \`true\` \n\t Name: \`${currentUser.name}\` \n\t Email: \`${currentUser.email}\` \n\t Org: \`${currentUser.org}\` \n\t Id Token: \`${idToken}\``,
                  channel: 'fe-auth-redirect-logs'
                })

                posthogCapture('User redirecting to Tryloop domain', {
                  name: currentUser.name,
                  email: currentUser.email,
                  org: currentUser.org,
                  idToken
                })

                logout()
                window.location.href = redirectUrl
              } else {
                const redirectUrl = `${BASE_FRONTEND_URL}/?redirect=${path}&redirected=true`

                await sendSlackNotification({
                  message: `\`Redirecting user\` to Tryloop domain`,
                  title: `Details: \n\t Logged in: \`false\` \n\t Name: \`-\` \n\t Email: \`-\` \n\t Org: \`-\` \n\t Id Token: \`-\``,
                  channel: 'fe-auth-redirect-logs'
                })

                posthogCapture('User redirecting to Tryloop domain')

                window.location.href = redirectUrl
              }

              setRedirectLoading(false)
            }
          }))
        }
      }
    } catch (err) {
      handleError(err)
    }
  }

  useEffect(() => {
    redirectUsersToTryloop()
  }, [currentUser, isCurrentUserSet])

  const contextValue = useMemo(
    () => ({
      currentUser,
      logout,
      orgConfig,
      orgConfigLoading,
      accessLevels,
      userList,
      userListLoading,
      googleStudioLinks,
      supersetLinks,
      defaultRoute,
      setDefaultRoute,
      setCurrentUser,
      isDemo,
      posthogCapture,
      getApiData,
      hasMagicLink,
      globalRoutes,
      globalRoutesLoading,
      standaloneRoutesLoaded,
      setStandaloneRoutesLoaded,
      redirectLoading,
      openRedirectionDialog,
      setOpenRedirectionDialog,
      getSubChainRoutes,
      getRoutes,
      getWizards,
      isOrgInActive,
      subChainData,
      refreshSubChainData,
      routeCategories,
      categoryLoading,
      categoryOrder
    }),
    [
      currentUser,
      logout,
      orgConfig,
      orgConfigLoading,
      accessLevels,
      userList,
      userListLoading,
      googleStudioLinks,
      supersetLinks,
      defaultRoute,
      setDefaultRoute,
      setCurrentUser,
      isDemo,
      posthogCapture,
      getApiData,
      hasMagicLink,
      globalRoutes,
      globalRoutesLoading,
      standaloneRoutesLoaded,
      setStandaloneRoutesLoaded,
      redirectLoading,
      openRedirectionDialog,
      setOpenRedirectionDialog,
      getSubChainRoutes,
      getRoutes,
      getWizards,
      isOrgInActive,
      subChainData,
      refreshSubChainData,
      routeCategories,
      categoryLoading,
      categoryOrder
    ]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
