import { useTheme } from '@mui/material'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { v4 as uuidv4 } from 'uuid'
import { DrawerMode } from '../types'

export interface Message {
  messageId: string
  sender: 'user' | 'ai'
  content: string
  senderMetadata?: {}
  thinkingStages?: string[]
  isThinkingLoaded?: boolean
  metadata?: {
    visualization_data?: {
      type: string
      data: {
        metrics: string[]
        current_period: number[]
        previous_period: number[]
        changes: number[]
        date_ranges: {
          current: {
            start: string
            end: string
          }
          previous: {
            start: string
            end: string
          }
        }
      }
    }
  }
  timestamp?: Date
  loading?: boolean
}
export const drawerWidth = 800
export const drawerWidthMin = 800
export const drawerWidthMax = 1200
export const drawerWidthPx = `${drawerWidth}px`
export const drawerWidthMinPx = `${drawerWidthMin}px`
export const drawerWidthMaxPx = `${drawerWidthMax}px`
export const drawerCloseWidth = 0
export const drawerCloseWidthPx = `${drawerCloseWidth}px`
export const isDryRun = false
let baseUrl = ''

if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://0.0.0.0:8000'
} else {
  baseUrl = 'https://langchain-service-ul5ne76yva-uc.a.run.app'
}

interface FluxChatContextType {
  isOpen: boolean
  messages: Message[]
  openChat: () => void
  closeChat: () => void
  sendMessage: (content: string, dryRun?: boolean) => Promise<void | (() => void)>
  isLoading: boolean
  toggleChat: () => void
  theme: any
  drawerMode: DrawerMode
  toggleDrawerMode: () => void
  clearMessages: () => void
  regenerateResponse: (message: Message, messageIndex: number) => void
  submitFeedback: (message: Message, isPositive: boolean) => void
  followUpQuestions: string[]
  setState: (state: any) => void
}

const FluxChatContext = createContext<FluxChatContextType | undefined>(undefined)

export function FluxChatProvider({ children }: { children: ReactNode }): JSX.Element {
  const { getFiltersV2 } = useFilter()
  const { currentUser } = useAuth()

  const theme = useTheme()
  const [state, setState] = useState({
    isOpen: false,
    isLoading: false,
    drawerMode: 'overlay' as DrawerMode
  })

  const [threadId, setThreadId] = useState<string | null>(uuidv4())
  const [messages, setMessages] = useState<Message[]>([])

  const toggleChat = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: !prev.isOpen }))
    if (!threadId) {
      setThreadId(uuidv4())
    }
  }, [])

  const openChat = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: true }))
  }, [])

  const closeChat = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }))
  }, [])

  const toggleDrawerMode = useCallback(() => {
    setState((prev) => ({
      ...prev,
      drawerMode: prev.drawerMode === 'overlay' ? 'fixed' : 'overlay'
    }))
  }, [])

  const clearMessages = useCallback(() => {
    setMessages([])
  }, [])

  const filters = getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)

  const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([])

  const defaultQuestions = useMemo(
    () => [
      `Please share the Flux Analysis report from ${filters.start_date_in} to ${filters.end_date_in}`,
      'What is the breakdown of the commission by platform?',
      'Can you share summary with highlights for last month',
      'Show me major fluctuation happened in last two months?'
    ],
    [filters.start_date_in, filters.end_date_in]
  )

  useEffect(() => {
    setFollowUpQuestions(defaultQuestions)
  }, [defaultQuestions, filters])

  const sendMessage = useCallback(
    async (userPrompt: string, dryRun?: boolean) => {
      setState((prev) => ({ ...prev, isLoading: true }))
      const messageId = uuidv4()
      let accumulatedContent = ''
      let stages: string[] = []

      try {
        const userMessage: Message = {
          messageId: messageId,
          content: userPrompt,
          sender: 'user',
          timestamp: new Date()
        }

        setMessages((prev) => [...prev, userMessage])
        // Add initial AI message with loading state
        setMessages((prev) => [
          ...prev,
          {
            messageId: messageId,
            content: '',
            sender: 'ai',
            loading: true
          }
        ])

        if (dryRun) {
          // Simulate API delay between 500ms and 1500ms
          await new Promise((resolve) => setTimeout(resolve, 500 + Math.random() * 5000))

          // Replace loading message with final response
          setMessages((prev) =>
            prev.map((msg) =>
              msg.messageId === messageId && msg.sender === 'ai'
                ? {
                    messageId: messageId,
                    content: 'Dry run completed - messageId: ' + messageId,
                    sender: 'ai',
                    timestamp: new Date()
                  }
                : msg
            )
          )
          setState((prev) => ({ ...prev, isLoading: false }))
          return () => {
            setState((prev) => ({ ...prev, isLoading: false }))
          }
        }

        const params = new URLSearchParams({
          chain_in: filters.chain_in,
          content: userPrompt,
          message_id_in: messageId,
          start_date_in: filters.start_date_in,
          end_date_in: filters.end_date_in,
          thread_id_in: currentUser.uid || threadId,
          user_id_in: currentUser.uid,
          user_name_in: currentUser.name
        })

        const eventSource = new EventSource(`${baseUrl}/api/chat/stream?${params.toString()}`, {
          withCredentials: false
        })

        eventSource.addEventListener('message', (event) => {
          try {
            const data = JSON.parse(event.data)
            if (data.type === 'token' && data.data && data.data.length > 0) {
              accumulatedContent += data.data
              setMessages((prev) =>
                prev.map((msg) => (msg.messageId === messageId && msg.sender === 'ai' ? { ...msg, content: accumulatedContent, loading: false } : msg))
              )
            }

            if (data.type === 'final' && data.data) {
              const finalContent = accumulatedContent || data.data
              setMessages((prev) =>
                prev.map((msg) =>
                  msg.messageId === messageId && msg.sender === 'ai'
                    ? {
                        ...msg,
                        content: finalContent,
                        loading: false,
                        timestamp: new Date(),
                        isThinkingLoaded: true
                      }
                    : msg
                )
              )
              setState((prev) => ({ ...prev, isLoading: false }))
              eventSource.close()
            }

            if (data.type === 'complete') {
              setMessages((prev) =>
                prev.map((msg) => (msg.messageId === messageId && msg.sender === 'ai' ? { ...msg, loading: false, isThinkingLoaded: true } : msg))
              )
              eventSource.close()

              setState((prev) => ({ ...prev, isLoading: false }))
            }

            if (data.type === 'suggested_questions' && data.data) {
              setFollowUpQuestions(data.data)
            }

            if (data.type === 'thinking_engine' && data.data && data.data.length > 0 && data.data !== '\n') {
              stages.push(data.data)
              setMessages((prev) => prev.map((msg) => (msg.messageId === messageId && msg.sender === 'user' ? { ...msg, thinkingStages: [...stages] } : msg)))
            }
          } catch (e) {
            console.error('Message parse error:', e)
          }
        })

        eventSource.addEventListener('final', (event) => {
          try {
            const data = JSON.parse(event.data)
            if (data?.data?.includes('✨ Completed!')) {
              setMessages((prev) => prev.map((msg) => (msg.messageId === messageId ? { ...msg, content: accumulatedContent, loading: false } : msg)))
              // Gracefully close the connection
              eventSource.close()
              setState((prev) => ({ ...prev, isLoading: false }))
            }
          } catch (e) {
            console.error('Final parse error:', e)
          }
        })

        eventSource.onerror = (error) => {
          console.error('EventSource failed:', error)
          setMessages((prev) =>
            prev.map((msg) =>
              msg.messageId === messageId && msg.sender === 'ai'
                ? {
                    ...msg,
                    content: 'Connection error. Please try again.',
                    loading: false
                  }
                : msg
            )
          )
          eventSource.close()
          // Ensure loading state is set to false on error
          setState((prev) => ({ ...prev, isLoading: false }))
        }

        // Cleanup function to ensure loading state is reset
        return () => {
          eventSource.close()
          setState((prev) => ({ ...prev, isLoading: false }))
        }
      } catch (error) {
        console.error('Stream error:', error)
        setMessages((prev) =>
          prev.map((msg) =>
            msg.messageId === messageId
              ? {
                  ...msg,
                  content: `Error: ${error.message || 'An error occurred'}`,
                  loading: false
                }
              : msg
          )
        )
        // Ensure loading state is set to false on error
        setState((prev) => ({ ...prev, isLoading: false }))
      }
    },
    [getFiltersV2, currentUser]
  )

  const regenerateResponse = useCallback(
    (_message: Message, messageIndex: number) => {
      if (messageIndex <= 0 || messageIndex > messages.length) {
        console.warn('Invalid message index for regeneration')
        return
      }

      const messageId = _message.messageId
      const message = messages.find((msg) => msg.messageId === messageId)
      if (!message || message.sender !== 'user') {
        console.warn('Valid user message not found')
        return
      }

      sendMessage(message.content, isDryRun)
    },
    [messages, sendMessage]
  )

  const submitFeedback = (message: Message, isPositive: boolean) => {
    console.log('submitFeedback', message, isPositive)
    // Implement feedback submission logic
  }

  const contextValue = useMemo(
    () => ({
      theme,
      toggleChat,
      isOpen: state.isOpen,
      messages,
      openChat,
      closeChat,
      sendMessage,
      isLoading: state.isLoading,
      drawerMode: state.drawerMode,
      toggleDrawerMode,
      clearMessages,
      regenerateResponse,
      submitFeedback,
      followUpQuestions,
      setState
    }),
    [messages, state.isOpen, state.isLoading, state.drawerMode, theme, followUpQuestions, setState]
  )

  return <FluxChatContext.Provider value={contextValue}>{children}</FluxChatContext.Provider>
}

export const useFluxChatContext = (): FluxChatContextType => {
  const context = useContext(FluxChatContext)
  if (!context) {
    throw new Error('useFluxChatContext must be used within FluxChatProvider')
  }
  return context
}
