import { LoopModule } from '@LoopKitchen/heyapi-client'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const DashboardTrendsOnly = lazy(() => import('src/pages/members/dashboard/subPages/TrendsOnly'))
const DashboardMetricsOnly = lazy(() => import('src/pages/members/dashboard/subPages/MetricsOnly'))
const DashboardErrorOverview = lazy(() => import('src/pages/members/dashboard/subPages/ErrorOverview'))
const DashboardSales = lazy(() => import('src/pages/members/dashboard/subPages/Sales'))
const DashboardRatingTrends = lazy(() => import('src/pages/members/dashboard/subPages/DashboardRatingTrends'))

const Reviews = lazy(() => import('src/components/Reviews/Reviews'))
const PlaidAuth = lazy(() => import('src/components/Plaid/PlaidAuth'))

const Errors = lazy(() => import('src/pages/members/Errors/Errors'))

const InAccurateOrders = lazy(() => import('src/pages/members/InAccurateOrders/InAccurateOrders'))

const StorePerformanceIntro = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceIntro'))

const StorePerformanceReport = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceReport'))
const StorePerformanceSubscribeUser = lazy(() => import('src/pages/members/StorePerformance/SubscribeUser'))

const StorePerformanceCadence = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceCadence'))

const WhatsNew = lazy(() => import('src/pages/members/Changelog/WhatsNew'))

const Configurations = lazy(() => import('src/pages/members/Configurations/ConfigurationCenter'))
const StoreAvailabilityReasonsConfigs = lazy(() => import('src/pages/members/StoreAvailabilityReasonsConfigs/StoreAvailabilityReasonsConfigs'))

const DrillDown = lazy(() => import('src/pages/members/DrillDown/DrillDown'))

const Benchmarks = lazy(() => import('src/pages/members/Benchmarks/Benchmarks'))

const AccountingSetup = lazy(() => import('src/pages/members/AccountingSetup/AccountingSetup'))

const AddAccountingSoftware = lazy(() => import('src/pages/members/AccountingSetup/AddAccountingSoftware'))

const AccountingConfigurationV2 = lazy(() => import('src/pages/members/AccountingSetup/AccountingConfigurationV2'))

const AccountingLocationStatus = lazy(() => import('src/pages/members/AccountingSetup/AccountingLocationStatus'))

const DrillDownExample = lazy(() => import('src/pages/members/DrillDownExample/DrillDownExample'))

const AccountingPortal = lazy(() => import('src/pages/members/AccountingPortal/AccountingPortal'))

const Chooseplateform = lazy(() => import('src/pages/members/credentials/Chooseplateform'))
const Process = lazy(() => import('src/pages/members/credentials/Process'))
const OrdersOld = lazy(() => import('src/pages/members/OrdersOld/Orders'))

const Landing = lazy(() => import('src/pages/members/Landing'))
const Visuals = lazy(() => import('src/pages/members/Visuals'))

//OLO
const OloTransactions = lazy(() => import('src/pages/members/OloTransactions/OloTransactions'))
const OloCustomers = lazy(() => import('src/pages/members/OloCustomers/OloCustomers'))
const OloItems = lazy(() => import('src/pages/members/OloItems/OloItems'))

//ORDER ACCURACY
const MissedOrders = lazy(() => import('src/pages/members/MissedOrders/MissedOrders'))
const CancelledOrders = lazy(() => import('src/pages/members/CancelledOrders/CancelledOrders'))

const Uploadcsv = lazy(() => import('src/pages/members/credentials/Uploadcsv'))
const IntegrationStatus = lazy(() => import('src/pages/members/IntegrationStatus'))
const Shift = lazy(() => import('src/pages/members/Shift/Shift'))
const ShiftLeaderboard = lazy(() => import('src/pages/members/Shift/Leaderboard'))

const UploadCsvDetail = lazy(() => import('src/pages/members/credentials/UploadCSVDetails'))
// const Finance = lazy(() => import('src/pages/members/Finance/Finance'));
// const FinanceBreakdown = lazy(() => import("src/pages/members/Finance/FinanceBreakdown"));
const ItemPerformance = lazy(() => import('src/pages/members/ItemPerformance/ItemPerformance'))
const ItemPerformancePage = lazy(() => import('src/pages/members/ItemPerformance/ItemPerformancePage'))
const SpecificItemPerformance = lazy(() => import('src/pages/members/ItemPerformance/SpecificItemPerformance/SpecificItemPerformance'))

// Mapping
const Mapping = lazy(() => import('src/pages/members/Mapping/Mapping'))
const OnboardStatus = lazy(() => import('src/pages/members/OnboardStatus/OnboardStatus'))

const Leaderboard = lazy(() => import('src/pages/members/Leaderboard'))
const ReportDownload = lazy(() => import('src/pages/members/Reporting/ReportDownload'))

// Delivery Intelligence
const ErrorInsights = lazy(() => import('src/pages/members/DeliveryIntelligence/ErrorInsights'))

const existingRoutes = [
  {
    path: 'list-orders',
    element: (
      <Navigate
        to="/list-orders/all"
        replace
      />
    )
  },
  { path: 'list-orders/all', element: <OrdersOld status="all" /> },
  {
    path: 'list-orders/cancelled',
    element: <OrdersOld status="cancelled" />
  },
  {
    path: 'list-orders/inaccurate',
    element: <OrdersOld status="inaccurate" />
  },
  { path: 'list-orders/missed', element: <OrdersOld status="missed" /> },

  { path: 'dashboard/v1/trends', element: <DashboardTrendsOnly /> },
  { path: 'dashboard/v1/metrics', element: <DashboardMetricsOnly /> },
  {
    path: 'dashboard/v1/error/overview',
    element: <DashboardErrorOverview />
  },
  { path: 'dashboard/v1/sales', element: <DashboardSales /> },
  { path: 'dashboard/v1/rating/trends', element: <DashboardRatingTrends /> },
  { path: 'base/benchmarks', element: <Benchmarks module={LoopModule.BASE} /> },

  { path: 'finance/bank/integration', element: <PlaidAuth /> },
  { path: 'reviews/summary', element: <Reviews /> },
  { path: 'reviews/summary/generate', element: <Reviews /> },
  {
    path: 'item-performance/details/:storeName/:itemName',
    element: <SpecificItemPerformance />
  },
  { path: 'item-performance/item-view', element: <ItemPerformance /> },
  { path: 'item-performance', element: <ItemPerformancePage /> },

  { path: 'integration-status', element: <IntegrationStatus /> },
  { path: 'visuals', element: <Visuals /> },
  { path: 'landing', element: <Landing /> },
  { path: 'shift', element: <Shift /> },
  { path: 'shift/leaderboard', element: <ShiftLeaderboard /> },
  { path: 'choose-platform', element: <Chooseplateform /> },
  { path: 'process', element: <Process /> },
  { path: 'upload-csv', element: <Uploadcsv /> },
  { path: 'upload-csv-detail', element: <UploadCsvDetail /> },

  { path: 'shift', element: <Shift /> },
  { path: 'onboard/data', element: <Mapping /> },
  { path: 'onboard/status', element: <OnboardStatus /> },
  { path: 'loop-store-mapping', element: <Mapping /> },

  { path: 'breakdown/example', element: <DrillDownExample /> },

  { path: 'store-performance/intro', element: <StorePerformanceIntro /> },
  { path: 'store-performance/report-type', element: <StorePerformanceReport /> },
  { path: 'store-performance/subscribe-user', element: <StorePerformanceSubscribeUser /> },
  { path: 'store-performance/report-cadence', element: <StorePerformanceCadence /> },

  { path: 'sandboxbookkeeping/gl-entries', element: <AccountingPortal /> },
  {
    path: 'sandboxbookkeeping/integrations',
    element: <AccountingSetup />
  },
  {
    path: 'sandboxbookkeeping/integrations/add',
    element: <AddAccountingSoftware />
  },
  { path: 'sandboxbookkeeping/map-gl', element: <AccountingConfigurationV2 /> },
  { path: 'sandboxbookkeeping/location/status', element: <AccountingLocationStatus /> },
  {
    path: 'loop-reports',
    element: (
      <Navigate
        to="/base/loop-reports"
        replace
      />
    )
  },
  {
    path: 'list-orders',
    element: (
      <Navigate
        to="/list-orders/all"
        replace
      />
    )
  },
  {
    path: 'all-orders/cancelled',
    element: <OrdersOld status="cancelled" />
  },
  {
    path: 'all-orders/inaccurate',
    element: <OrdersOld status="inaccurate" />
  },
  { path: 'all-orders/missed', element: <OrdersOld status="missed" /> },
  {
    path: 'order-accuracy',
    element: <Navigate to={`/order-accuracy/overview${window.location.search}`} />
  },
  { path: 'order-accuracy/overview', element: <Errors /> },
  { path: 'order-accuracy/inaccurate-orders', element: <InAccurateOrders /> },
  { path: 'order-accuracy/inaccurate-orders/:filter', element: <InAccurateOrders /> },
  { path: 'order-accuracy/missed-orders', element: <MissedOrders /> },
  { path: 'order-accuracy/missed-orders/:filter', element: <MissedOrders /> },
  { path: 'order-accuracy/cancelled-orders', element: <CancelledOrders /> },
  { path: 'order-accuracy/cancelled-orders/:filter', element: <CancelledOrders /> },
  { path: 'drill-down/:name', element: <DrillDown /> },
  { path: 'changelog', element: <WhatsNew /> },
  { path: 'configurations', element: <Configurations /> },
  { path: 'configurations/store-availability', element: <Configurations /> },
  { path: 'configurations/store-availability/reasons', element: <StoreAvailabilityReasonsConfigs /> },

  {
    path: 'base/error-insights',
    element: <ErrorInsights />
  },
  {
    path: 'base/leaderboard',
    element: <Leaderboard />
  }
]

const moduleNamedRoutes = [
  {
    path: 'base',
    children: [
      { path: 'trends', element: <DashboardTrendsOnly /> },
      { path: 'metrics', element: <DashboardMetricsOnly /> },
      { path: 'errors', element: <DashboardErrorOverview /> },
      { path: 'benchmarks', element: <Benchmarks module={LoopModule.BASE} /> },
      { path: 'sales', element: <DashboardSales /> },
      { path: 'loop-reports', element: <ReportDownload /> },
      {
        path: 'all-orders',
        children: [
          { path: 'overview', element: <OrdersOld status="all" /> },
          { path: 'inaccurate', element: <OrdersOld status="inaccurate" /> },
          { path: 'inaccurate/:filter', element: <OrdersOld status="inaccurate" /> },
          { path: 'missed', element: <OrdersOld status="missed" /> },
          { path: 'missed/:filter', element: <OrdersOld status="missed" /> },
          { path: 'cancelled', element: <OrdersOld status="cancelled" /> },
          { path: 'cancelled/:filter', element: <OrdersOld status="cancelled" /> }
        ]
      },
      { path: 'leaderboard', element: <Leaderboard /> }
    ]
  },
  {
    path: 'sandboxbookkeeping',
    children: [
      { path: 'gl-entries', element: <AccountingPortal /> },
      { path: 'integrations', element: <AccountingSetup /> },
      { path: 'integrations/add', element: <AddAccountingSoftware /> },
      { path: 'map-gl', element: <AccountingConfigurationV2 /> },
      { path: 'location/status', element: <AccountingLocationStatus /> }
    ]
  },
  {
    path: 'store-performance',
    children: [
      { path: 'intro', element: <StorePerformanceIntro /> },
      { path: 'report-type', element: <StorePerformanceReport /> },
      { path: 'subscribe-user', element: <StorePerformanceSubscribeUser /> },
      { path: 'report-cadence', element: <StorePerformanceCadence /> }
    ]
  },
  {
    path: 'olo',
    children: [
      {
        path: 'transactions',
        element: <OloTransactions />
      },
      {
        path: 'customers',
        element: <OloCustomers />
      },
      {
        path: 'items',
        element: <OloItems />
      }
    ]
  }
]

export const baseRoutes = [...existingRoutes, ...moduleNamedRoutes]
