import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth'
import React, { Suspense, useEffect } from 'react'
import { unstable_HistoryRouter as HistoryRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import theme from 'src/theme/defaultTheme'
import { POST_HOG_BASE_URL } from './assets/configs/globalConst'
import Loading from './components/Loading'
import { OpenAPI } from './services/openApiV2'
import { APP_VERSION, BASE_API_HOST, BASE_API_URL, BASE_FIREBASE_FUNCTIONS_URL } from './utils/config/config'
import history from './utils/config/history'

// Context Providers
import ErrorContextProvider from 'src/context/ErrorContext'
import LoadingContextProvider from 'src/context/LoadingContext'
import OrderContextProvider from 'src/context/OrderContext'
import SnackContextProvider from 'src/context/SnackContext'
import Router from 'src/routes/Routes'
import BugReportContextProvider from './context/BugReportContext'
import PublicAuthContextProvider from './context/PublicAuthContext'

// Sentry Initialization
import { BrowserTracing, Replay, init, reactRouterV6Instrumentation, withProfiler } from '@sentry/react'

// HyperDX Initialization
import HyperDX from '@hyperdx/browser'

// PostHog Initialization
import posthog from 'posthog-js'
import { sendSlackNotification } from './utils/api'
import { getSession } from './utils/functions/localStorage'

// Global Declarations
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier
    confirmationResult: ConfirmationResult
    dataLayer: any[]
    loop: any
    getComputedStyle: (elt: Element, pseudoElt?: string) => CSSStyleDeclaration
  }
}

// PostHog Initialization
posthog.init('phc_sikWFIgkDh7HTtJ6C3Gx5CTU3DvS21g5acZ8lZ9HRyi', {
  api_host: POST_HOG_BASE_URL,
  enable_recording_console_log: true,
  session_recording: {
    maskAllInputs: false,
    maskNetworkRequestFn: (request) => request
  }
})

// Sentry Initialization
init({
  dsn: 'https://31ca9b004e6d439181967f14e7f76394@o4504559796682752.ingest.sentry.io/4504650246062080',
  integrations: [
    new posthog.SentryIntegration(posthog, 'loop-frontend', 4504650246062080),
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes)
    }),
    new Replay()
  ],
  tunnel: `${BASE_FIREBASE_FUNCTIONS_URL}/fig`,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: APP_VERSION
})

if (process.env.NODE_ENV === 'production') {
  HyperDX.init({
    apiKey: '181046a8-875e-4969-8892-37ccc8fc9599',
    service: 'loop-frontend',
    tracePropagationTargets: [BASE_API_HOST], // Set to link traces from frontend to backend requests
    consoleCapture: true, // Capture console logs (default false)
    advancedNetworkCapture: true // Capture full HTTP request/response headers and bodies (default false)
  })
}

// Set API Base URL
OpenAPI.BASE = BASE_API_URL

const App = React.memo(() => {
  useEffect(() => {
    window.addEventListener('error', (event) => {
      if (event.message.includes('ChunkLoadError')) {
        const userData = getSession('data')
        sendSlackNotification({
          message: `ChunkLoadError: ${event.message}`,
          channel: 'fe-logs',
          title: `Chunk Load Error ${userData && userData?.email ? `for \`${userData.email}\`` : ''}`
        })

        // Clear caches
        caches.keys().then((keys) => {
          return Promise.all(keys.map((key) => caches.delete(key)))
        })

        // Unregister Service Worker
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => registration.unregister())
          })
        }

        // Delete IndexedDB
        if (window.indexedDB) {
          indexedDB.databases().then((dbs) => {
            dbs.forEach((db) => indexedDB.deleteDatabase(db.name))
          })
        }

        // Reload the page
        setTimeout(() => window.location.reload(), 500)
      }
    })
  }, [])

  return (
    <>
      <div id="recaptcha-container" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={5}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        closeButton={true}
        theme="colored"
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* @ts-expect-error */}
        <HistoryRouter history={history}>
          <LoadingContextProvider>
            <SnackContextProvider>
              <ErrorContextProvider>
                <PublicAuthContextProvider>
                  <OrderContextProvider>
                    <BugReportContextProvider>
                      <Suspense fallback={<Loading />}>
                        <Router />
                      </Suspense>
                    </BugReportContextProvider>
                  </OrderContextProvider>
                </PublicAuthContextProvider>
              </ErrorContextProvider>
            </SnackContextProvider>
          </LoadingContextProvider>
        </HistoryRouter>
      </ThemeProvider>
    </>
  )
})

export default withProfiler(App)
