import { Box, Fade, Popper, Skeleton, Typography, alpha, useMediaQuery } from '@mui/material'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { CategorizedRoutesType, usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { useWizardContext } from 'src/context/WizardContext'
import { debounce, get } from 'src/utils/config/lodashUtils'
import { drawerCloseWidth, drawerWidth } from '..'
import CategoryTab from './CategoryTab'
import CustomLink from './CustomLink'
import HomePageLink from './HomePageLink'
import RefreshStatus, { refreshCollapsedWidth, refreshExpandedWidth } from './RefreshStatus'
import RefreshStatusSmall from './RefreshStatusSmall'
import SideBarHeader from './SideBarHeader'
import SidebarSearch from './SidebarSearch'
import SubNav from './SubNav'
import WizardTab from './WizardTab'

export interface WizardType {
  key: string
  label: string
  hide: boolean
  icon?: string
  steps: {
    label: string
    key: string
  }[]
  routeKey: string
}

interface SideBarRouteListProps {
  isSidebarOpen: boolean
  hideRefreshStatus?: boolean
  hideWizardList?: boolean
}
export default function SideBarRouteList(props: SideBarRouteListProps): JSX.Element {
  // ========== Contexts ==========
  const { globalRoutesLoading, orgConfigLoading, categoryLoading } = useAuth()
  const { categorizedRoutes, open, setOpen, theme } = usePrivateLayoutDrawerContext()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const { filteredWizardConfig, wizardConfigLoading } = useWizardContext()
  const { getFiltersV2, getAllPossibleFilters } = useNewFilterContext()

  // ========== States ==========
  const [routeSearchText, setRouteSearchText] = useState('')
  const [activeCategory, setActiveCategory] = useState<string>(null)
  const [selectedCategory, setSelectedCategory] = useState<string>(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [, setPopperHeight] = useState(0)
  const [refreshExpanded, setRefreshExpanded] = useState(false)
  const [fixedSidebarHeight, setFixedSidebarHeight] = useState(0)
  const [delayedOpen, setDelayedOpen] = useState(false)
  const fixedSidebarRef = useRef(null)

  // ========== Functions ==========
  const showRoute = (route, category = null) => {
    const lowerCaseSearchText = routeSearchText.toLowerCase()
    const matchingSearchText =
      category?.name?.toLowerCase().includes(lowerCaseSearchText) ||
      route.label?.toLowerCase().includes(lowerCaseSearchText) ||
      route.subNav?.some((subNav) => subNav.label?.toLowerCase().includes(lowerCaseSearchText))
    return route.showInSidebar && !route.hidden && matchingSearchText
  }

  const closeDrawer = () => {
    if (sm) setOpen(false)
  }

  // ========== Memos ==========
  const sidebarLoading = useMemo(() => {
    return orgConfigLoading || categoryLoading || wizardConfigLoading || globalRoutesLoading
  }, [orgConfigLoading, categoryLoading, wizardConfigLoading, globalRoutesLoading])

  const filteredCategorizedRoutes = useMemo(() => {
    const result: CategorizedRoutesType[] = []
    const searchText = routeSearchText.toLowerCase()
    categorizedRoutes.forEach((categorizedRoute) => {
      const category = categorizedRoute.category

      if (category.name.toLowerCase().includes(searchText)) {
        result.push(categorizedRoute)
        return
      }

      const filteredRoutes = categorizedRoute.routes.filter((route) => showRoute(route, category))

      if (filteredRoutes.length) {
        result.push({
          category,
          routes: filteredRoutes,
          showInSidebar: categorizedRoute.showInSidebar
        })
      }
    })

    return result
  }, [categorizedRoutes, routeSearchText])

  const wizardList: WizardType[] = useMemo(() => {
    if (!filteredWizardConfig) {
      return []
    }
    const keys = Object.keys(filteredWizardConfig)
    const result = keys.map((key) => {
      return {
        routeKey: key,
        ...filteredWizardConfig[key]
      }
    })
    return result
  }, [filteredWizardConfig])

  const showRefreshDateNotification = useMemo(() => {
    const selectedBNames = getFiltersV2(['b_name'])?.b_name_in?.split('|')
    const allBNames = getAllPossibleFilters(['b_name'])?.b_name

    return selectedBNames.length !== 0 && selectedBNames[0] !== '' && selectedBNames.length !== allBNames.length
  }, [getFiltersV2(['b_name'])?.b_name_in, getAllPossibleFilters(['b_name'])?.b_name?.join('|')])

  const categoriesSectionHeight = useMemo(() => {
    return (
      (sidebarLoading ? 8 : filteredCategorizedRoutes.length + (wizardList.length > 0 ? 1 : 0)) * (drawerCloseWidth - 2) +
      (wizardList.length > 0 ? 16 : 0) + // 16px for bottom border
      4 // 4px for extra py
    )
  }, [sidebarLoading, filteredCategorizedRoutes, wizardList])

  const routesSectionHeight = useMemo(() => {
    return fixedSidebarHeight + categoriesSectionHeight > window?.innerHeight ? categoriesSectionHeight : 'auto'
  }, [fixedSidebarHeight, categoriesSectionHeight])

  const getFirstRoute = (category: CategorizedRoutesType) => {
    const subNavKey = get(category, 'routes[0].subNav[0].key', '')
    const routeKey = get(category, 'routes[0].key', '')

    if (subNavKey?.startsWith('/')) {
      return subNavKey
    } else {
      if (subNavKey && subNavKey !== '') {
        return `${routeKey}/${subNavKey}`
      } else {
        return routeKey
      }
    }
  }

  // ========== Callbacks ==========
  const setRef = useCallback((node) => {
    if (node) {
      const debouncedSetBoxHeight = debounce((height) => {
        setPopperHeight(height)
      }, 10)

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          debouncedSetBoxHeight(entry.contentRect.height)
        }
      })
      resizeObserver.observe(node)

      return () => {
        resizeObserver.unobserve(node)
        resizeObserver.disconnect()
      }
    }
  }, [])

  // ========== Effects ==========
  useEffect(() => {
    if (!categorizedRoutes) return

    const activeCategory = categorizedRoutes.find((category) =>
      category.routes.some((route) => {
        const routeKey = get(route, 'key', '').toLowerCase()
        const subNav = get(route, 'subNav', [])

        const isRouteActive = location.pathname.includes(routeKey)
        const isSubNavActive = subNav?.length > 0 ? subNav.some((subNavItem) => location.pathname.includes(get(subNavItem, 'key', '').toLowerCase())) : true

        return isRouteActive && isSubNavActive
      })
    )

    const activeCategoryName = get(activeCategory, 'category.key', null)

    setActiveCategory(activeCategoryName)
    setSelectedCategory((prev) => activeCategoryName ?? prev ?? 'base_routes')
  }, [categorizedRoutes, location.pathname])

  useEffect(() => {
    if (open && !activeCategory) {
      setSelectedCategory(filteredCategorizedRoutes[0]?.category?.key)
    } else {
      setSelectedCategory(activeCategory)
    }
  }, [open, activeCategory, filteredCategorizedRoutes])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setFixedSidebarHeight(fixedSidebarRef.current?.offsetHeight || 0)
    })

    if (fixedSidebarRef.current) resizeObserver.observe(fixedSidebarRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [fixedSidebarRef])

  useEffect(() => {
    if (!anchorEl) {
      setDelayedOpen(false)
      return
    }

    const timeout = setTimeout(() => {
      setDelayedOpen(true)
    }, 300)

    return () => clearTimeout(timeout)
  }, [anchorEl])

  return (
    <Box
      sx={{
        mb: sm && '70px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Box ref={fixedSidebarRef}>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'block'
            }
          }}>
          <SideBarHeader />
        </Box>

        {props.isSidebarOpen && (
          <>
            <Box borderBottom="1px solid #19394d" />
            <SidebarSearch
              searchText={routeSearchText}
              handleChange={(e) => setRouteSearchText(e?.target?.value || '')}
            />
          </>
        )}

        <Box borderBottom="1px solid #19394d" />

        <Box>
          <HomePageLink
            open={open}
            closeDrawer={closeDrawer}
            sm={sm}
          />
        </Box>

        <Box borderBottom="1px solid #19394d" />
      </Box>

      {!sm ? (
        <Box
          display="flex"
          alignItems="stretch"
          bgcolor="#122937"
          height="100%"
          sx={{
            overflow: 'auto',
            overflowX: 'hidden',
            '&.MuiBox-root::-webkit-scrollbar': {
              display: 'none !important'
            }
          }}
          onMouseLeave={() => {
            if (!open) {
              setAnchorEl(null)
              setSelectedCategory(null)
            }
          }}>
          <Box
            width={open ? drawerCloseWidth : '100%'}
            height={categoriesSectionHeight}
            bgcolor="#102634"
            borderBottom="1px solid #19394d"
            py="2px"
            zIndex={10}
            display="flex"
            flexDirection="column"
            sx={{
              transitionDelay: !open && '225ms'
            }}>
            {sidebarLoading ? (
              Array.from({ length: 8 }).map((_, index) => (
                <Box
                  key={index}
                  width={'100%'}
                  height={drawerCloseWidth - 2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={drawerCloseWidth - 6}
                    height={drawerCloseWidth - 6}
                    sx={{
                      mx: 'auto',
                      bgcolor: alpha('#2F4A5A', 0.6),
                      borderRadius: '4px'
                    }}
                  />
                </Box>
              ))
            ) : (
              <Fade
                in={!sidebarLoading}
                timeout={500}>
                <Box>
                  {wizardList?.length > 0 && (
                    <>
                      <WizardTab
                        activeCategory={activeCategory}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        setAnchorEl={setAnchorEl}
                      />
                      <Box
                        borderTop="1px solid #fff"
                        my="8px"
                        mx="auto"
                        width="70%"
                      />
                    </>
                  )}
                  {filteredCategorizedRoutes?.map((category, index) => {
                    return (
                      <CategoryTab
                        key={get(category, 'category.key', null) + index}
                        category={category}
                        activeCategory={activeCategory}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        setAnchorEl={setAnchorEl}
                        firstRoute={getFirstRoute(category)}
                      />
                    )
                  })}
                </Box>
              </Fade>
            )}
          </Box>

          <Box
            width={drawerWidth - drawerCloseWidth}
            height={routesSectionHeight}
            pb={
              refreshExpanded
                ? `${refreshExpandedWidth + 20 + (showRefreshDateNotification ? 40 : 0)}px`
                : `${refreshCollapsedWidth + 20 + (showRefreshDateNotification ? 40 : 0)}px`
            }
            display="flex"
            flexDirection="column"
            gap="3px"
            pt="2px"
            borderBottom="1px solid #19394d"
            borderLeft="1px solid #19394d"
            ml={open ? 0 : -(drawerWidth - drawerCloseWidth)}
            sx={{
              transition: '225ms cubic-bezier(0.4, 0, 0.6, 1)',
              overflow: 'hidden',
              overflowY: 'auto'
            }}>
            {sidebarLoading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  animation="wave"
                  width="calc(100% - 8px)"
                  height="46px"
                  sx={{
                    bgcolor: alpha('#2F4A5A', 0.6),
                    mx: '4px',
                    borderRadius: '4px'
                  }}
                />
              ))
            ) : (
              <Fade
                in={!sidebarLoading}
                timeout={500}>
                <Box>
                  {selectedCategory === 'wizard'
                    ? wizardList.map((wizard) => {
                        return (
                          <CustomLink
                            key={wizard.key}
                            open={open}
                            closeDrawer={closeDrawer}
                            to={`/wizard/${wizard.routeKey}`}
                            label={get(wizard, 'label')}
                            icon={get(wizard, 'icon', 'block')}
                            isSubNav={false}
                            isLinkActive={false}
                          />
                        )
                      })
                    : filteredCategorizedRoutes
                        .find((category) => category.category.key === selectedCategory)
                        ?.routes.map((navItem) => {
                          return navItem && navItem?.subNav && navItem?.subNav?.length ? (
                            <SubNav
                              key={navItem?.uid}
                              searchText={routeSearchText}
                              open={open}
                              closeDrawer={closeDrawer}
                              page={navItem}
                              type={navItem.type}
                              icon={navItem?.icon || 'document_scanner'}
                              newRoute={navItem.created_at && moment().diff(moment(navItem.created_at), 'days') < 7}
                            />
                          ) : (
                            <CustomLink
                              key={navItem?.uid}
                              open={open}
                              closeDrawer={closeDrawer}
                              to={`/${navItem?.key}`}
                              label={get(navItem, 'label')}
                              icon={navItem?.icon}
                              isSubNav={false}
                              isLinkActive={false}
                              pageTitle={navItem?.pageTitle}
                              type={navItem.type}
                              newRoute={navItem.created_at && moment().diff(moment(navItem.created_at), 'days') < 7}
                            />
                          )
                        })}
                </Box>
              </Fade>
            )}

            <Box
              position="fixed"
              bottom={0}
              bgcolor="#122937"
              width={drawerWidth - drawerCloseWidth - 8}>
              <RefreshStatus
                setRefreshExpanded={setRefreshExpanded}
                closeDrawer={closeDrawer}
                showRefreshDateNotification={showRefreshDateNotification}
              />
            </Box>
          </Box>

          <Popper
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement="right"
            sx={{
              zIndex: 1500,
              transition: delayedOpen ? '0.3s ease' : 'none'
            }}>
            <Box
              ref={setRef}
              px={0.5}>
              <Box
                width={drawerWidth - drawerCloseWidth}
                py={1}
                borderRadius="4px"
                height="100%"
                display="flex"
                flexDirection="column"
                bgcolor="#122937"
                maxHeight={window?.innerHeight - 20}
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  '&.MuiBox-root::-webkit-scrollbar-track': {
                    borderRadius: '10px',
                    backgroundColor: theme.colors.navyBlack
                  },
                  '&.MuiBox-root::-webkit-scrollbar': {
                    borderRadius: '8px',
                    width: open ? '7px' : '4px',
                    backgroundColor: theme.colors.navyBlack
                  },
                  '&.MuiBox-root::-webkit-scrollbar-thumb': {
                    borderRadius: '10px',
                    backgroundColor: '#2F4A5A'
                  }
                }}>
                {sidebarLoading
                  ? Array.from({ length: 8 }).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height="40px"
                        sx={{
                          bgcolor: alpha('#2F4A5A', 0.6),
                          my: '2px',
                          mx: '3px'
                        }}
                      />
                    ))
                  : selectedCategory === 'wizard'
                    ? wizardList.map((wizard) => {
                        return (
                          <CustomLink
                            key={wizard.key}
                            open={open}
                            closeDrawer={closeDrawer}
                            to={`/wizard/${wizard.routeKey}`}
                            label={get(wizard, 'label')}
                            icon={get(wizard, 'icon', 'block')}
                            isSubNav={false}
                            isLinkActive={false}
                          />
                        )
                      })
                    : filteredCategorizedRoutes
                        .find((category) => category.category.key === selectedCategory)
                        ?.routes.map((navItem) => {
                          return navItem && navItem?.subNav && navItem?.subNav?.length ? (
                            <SubNav
                              key={navItem?.uid}
                              searchText={routeSearchText}
                              open={open}
                              closeDrawer={closeDrawer}
                              page={navItem}
                              type={navItem.type}
                              icon={navItem?.icon || 'document_scanner'}
                              closePopper={() => setAnchorEl(null)}
                              newRoute={navItem.created_at && moment().diff(moment(navItem.created_at), 'days') < 7}
                            />
                          ) : (
                            <CustomLink
                              key={navItem?.uid}
                              open={open}
                              closeDrawer={closeDrawer}
                              to={`/${navItem?.key}`}
                              label={get(navItem, 'label')}
                              icon={navItem?.icon}
                              isSubNav={false}
                              isLinkActive={false}
                              pageTitle={navItem?.pageTitle}
                              type={navItem.type}
                              closePopper={() => setAnchorEl(null)}
                              newRoute={navItem.created_at && moment().diff(moment(navItem.created_at), 'days') < 7}
                            />
                          )
                        })}
              </Box>
            </Box>
          </Popper>
        </Box>
      ) : (
        <Box
          width="100%"
          borderRadius="4px"
          display="flex"
          flexDirection="column"
          bgcolor="#122937"
          mb="80px"
          sx={{
            overflowY: sm && 'auto'
          }}>
          {sidebarLoading ? (
            Array.from({ length: 10 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                animation="wave"
                width="100%"
                height="40px"
                sx={{
                  bgcolor: alpha('#2F4A5A', 0.6),
                  my: '2px',
                  mx: '3px'
                }}
              />
            ))
          ) : (
            <Fade
              in={!sidebarLoading}
              timeout={500}>
              <Box>
                {filteredCategorizedRoutes.map((category, index) => {
                  return (
                    <Box key={category.category ? category.category.key : index}>
                      {props.isSidebarOpen && (
                        <Box sx={{ px: 2, mt: '26px', mb: '6px' }}>
                          <Typography sx={{ color: '#89949B', opacity: 0.5, fontWeight: 600, fontSize: '12px' }}>
                            {get(category, 'category.name', 'Others')}
                          </Typography>
                        </Box>
                      )}

                      {get(category, 'routes', [])
                        .filter((route) => route.showInSidebar)
                        .map((navItem) => {
                          return navItem && navItem?.subNav && navItem?.subNav?.length ? (
                            <SubNav
                              key={navItem?.uid}
                              searchText={routeSearchText}
                              open={open}
                              closeDrawer={closeDrawer}
                              page={navItem}
                              type={navItem.type}
                              icon={navItem?.icon || 'document_scanner'}
                              closePopper={() => setAnchorEl(null)}
                              newRoute={navItem.created_at && moment().diff(moment(navItem.created_at), 'days') < 7}
                            />
                          ) : (
                            <CustomLink
                              key={navItem?.uid}
                              open={open}
                              closeDrawer={closeDrawer}
                              to={`/${navItem?.key}`}
                              label={get(navItem, 'label')}
                              icon={navItem?.icon}
                              isSubNav={false}
                              isLinkActive={false}
                              pageTitle={navItem?.pageTitle}
                              type={navItem.type}
                              closePopper={() => setAnchorEl(null)}
                              newRoute={navItem.created_at && moment().diff(moment(navItem.created_at), 'days') < 7}
                            />
                          )
                        })}
                    </Box>
                  )
                })}
              </Box>
            </Fade>
          )}
          <Box pt={2}>
            <RefreshStatus
              setRefreshExpanded={setRefreshExpanded}
              closeDrawer={closeDrawer}
              showRefreshDateNotification={showRefreshDateNotification}
            />
          </Box>
        </Box>
      )}

      {!open && <RefreshStatusSmall closeDrawer={closeDrawer} />}
    </Box>
  )
}
