import { CsvBuilder } from 'filefy'
import { forEach } from 'src/utils/config/lodashUtils'

import moment from 'moment'
import { User, UserType } from 'src/context/AuthContext.type'
import { UserDataTypes } from 'src/pages/members/User/Mangement/UserManagement'
import { DefaultService, ErrorHourWeek, PLATFORM } from 'src/services/openApiV2'
import { Api } from 'src/utils/apiV2/Api'
import { get, uniqBy } from 'src/utils/config/lodashUtils'
import { LOOPAIXYZ_DOMAIN, LOOPKITCHENXYZ_DOMAIN, TRYLOOPAI_DOMAIN } from './config/config'
import { pluralize } from './functions/pluralize'

// src/utils/loadCSS.js
export const loadCSS = (href) => {
  return new Promise<void>((resolve, reject) => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = href
    link.onload = () => resolve()
    link.onerror = () => reject(new Error(`Failed to load CSS: ${href}`))
    document.head.appendChild(link)
  })
}

interface ChartDataType {
  name: string
  data: number[]
  color?: string
}
interface HourType {
  hours: number
  dayofweek: number
  Count_sum: number
}
const {
  run: { runProcedureAndGetDataRunQueryV2Post }
} = new Api()
export function getChartFormattedData(chartResponse: ErrorHourWeek[], color: string) {
  const chartData: ChartDataType[] = [...Array(24).keys()].map((key) => ({
    name: `${key < 10 ? '0' + key.toString() : key.toString()}:00`,
    data: [0, 0, 0, 0, 0, 0, 0],
    color
  }))

  const getTime = (item: { name: string }) => parseInt(item.name.split(':')[0])

  function getChartObj(time: number) {
    let position = 0
    chartData.forEach((item, index) => {
      try {
        const time1 = getTime(item)
        if (index !== chartData.length - 1 && time1 <= time && getTime(chartData[index + 1]) > time) {
          position = index
        }
        if (index === chartData.length - 1 && time1 <= time) {
          position = index
        }
      } catch (error) {
        console.log(error.message)
      }
    })
    return position
  }
  forEach(chartResponse, (item: ErrorHourWeek) => {
    const timeIndex = getChartObj(item.hour)
    const chart = chartData[timeIndex]
    chart.data[item.dayofweek - 1] = chart.data[item.dayofweek - 1] + item.Count_sum
    chart.color = color
  })
  return chartData
}

export const getDisplayableName = (impact_name: string) => {
  const namesArr = impact_name?.split('_')
  let name = ''
  for (let i = 0; i < namesArr?.length; i++) {
    name += namesArr[i][0].toUpperCase() + namesArr[i].substring(1) + ' '
  }
  return name
}

export function getDateRange(dateRange) {
  const startDate = dateRange ? dateRange.start.format('MM-DD-YYYY') : ''
  const endDate = dateRange ? dateRange.end.format('MM-DD-YYYY') : ''
  const rangeValue = startDate && endDate ? `${startDate} to ${endDate}` : ''
  return rangeValue
}

export function formatCurrency(amount: number, obj?: { maxFractionDigits?: number; notation?: 'compact' }) {
  const formatting_options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: typeof obj?.maxFractionDigits === 'number' ? obj?.maxFractionDigits : 2,
    notation: obj?.notation
  }
  const dollarString = new Intl.NumberFormat('en-US', formatting_options)
  return dollarString.format(amount)
}

export function translateMinutesToDaysAndHours(minutes: number, obj?: { maxFractionDigits?: number; notation?: 'compact' }) {
  const days = Math.floor(minutes / (60 * 24))
  const hours = Math.floor((minutes % (60 * 24)) / 60)
  const minutesLeft = minutes % 60

  // Function to format numbers based on maxFractionDigits
  const formatNumber = (num: number): string => {
    if (obj?.maxFractionDigits !== undefined) {
      return num.toFixed(obj.maxFractionDigits)
    }
    return num.toString()
  }

  if (days === 0 && hours === 0) return `${formatNumber(minutesLeft)}m`
  if (days === 0) return `${formatNumber(hours)}h ${formatNumber(minutesLeft)}m`
  return `${formatNumber(days)}d ${formatNumber(hours)}h ${formatNumber(minutesLeft)}m`
}

export function formatNumber(n: number, obj?: { maxFractionDigits?: number; notation?: 'compact' }) {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: typeof obj?.maxFractionDigits === 'number' ? obj?.maxFractionDigits : 2,
    notation: obj?.notation
  }).format(n)
}

export async function getCategory(reason: string, getFilters: any, reasonCategory?: boolean) {
  try {
    const links = {
      InAccurate: 'inaccurate',
      Cancelled: 'cancelled',
      Missed: 'missed'
    }
    const response = (await DefaultService.runProcedureAndGetDataRunQueryPost({
      procedureName: 'data_visualization_metrics.procedure_category_sub_catergory_reasons_actions',
      requestBody: getFilters([
        'b_name',
        'vb_name',
        'chain',
        'platform',
        'am_name',
        `_${reasonCategory ? reason : ''}`,
        `_${!reasonCategory ? reason : ''}`,
        'start_date',
        'end_date'
      ])
    })) as any
    if (response && response.length > 0) {
      return links[response[0].error_category]
    } else {
      return 'overview'
    }
  } catch (error: any) {
    console.log(error.message)
  }
}

export async function getCategoryV2(reason: string, getFilters: any, field: string) {
  try {
    const links = {
      InAccurate: 'inaccurate-orders',
      Cancelled: 'cancelled-orders',
      Missed: 'missed-orders'
    }
    const response = await runProcedureAndGetDataRunQueryV2Post(
      { procedure_name: 'procedure_category_sub_catergory_reasons_actions' },
      getFilters(['b_name', 'vb_name', 'chain', 'platform', 'am_name', `#${field}#${reason}`, 'start_date', 'end_date']),
      {}
    )
    if (response.status === 200 && response.data.length > 0) {
      return links[response.data[0].error_category]
    } else {
      return 'overview'
    }
  } catch (error: any) {
    console.log(error.message)
  }
}

export const dollarFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })

export function getPositiveValue(num: number) {
  if (num > 0) return num
  else if (num <= 0) return 0 - num
}

export function calculateDelta(currentValue: number, prevValue: number) {
  if (prevValue === 0) return 0
  const result = ((currentValue - prevValue) * 100) / prevValue
  return parseFloat(result.toFixed(2))
}

export function exportCsv(columns, data, title: string, dateFormat?: string) {
  const newColumns = columns.filter((item) => get(item, 'hidden', false) === false)
  const csvData = data.map((rowData) => {
    return newColumns.map((item) => {
      if (item.type === 'date' || item.type === 'datetime') return moment(rowData[item.field]).format(dateFormat || 'DD-MM-YY')
      if (item.type === 'currency') return formatCurrency(parseInt(rowData[item.field]))
      return rowData[item.field]
    })
  })
  const builder = new CsvBuilder(`${title}.csv`)
    .setColumns(newColumns.map((item) => item.title))
    .addRows(csvData)
    .exportFile()

  return builder
}

export function downloadCsv(filename: string, csvData: any) {
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
}

export function getShortOrderId(platform: string, orderId: string) {
  if (platform?.toLowerCase() === PLATFORM?.DOORDASH?.toLowerCase()) {
    return orderId?.toLowerCase()?.slice(-8)
  } else if (platform?.toLowerCase() === PLATFORM?.UBER_EATS?.toLowerCase()) {
    return orderId?.toUpperCase()?.slice(-5)
  } else {
    return orderId
  }
}

export function getAccessRoutes(accessLevel: string, orgConfig) {
  let accessRoutes = []
  if (typeof accessLevel === 'string') {
    accessRoutes = uniqBy(accessRoutes.concat(get(orgConfig, `accessConfig.${accessLevel}.navConfig`, [])), 'key')
  } else if (typeof accessLevel === 'object' && Array.isArray(accessLevel)) {
    ;(accessLevel as string[]).forEach((item) => {
      const route = get(orgConfig, `accessConfig.${item}.navConfig`, [])
      accessRoutes = uniqBy(accessRoutes.concat(route), 'key')
    })
  }
  return accessRoutes
}

export const getRandomUserAvatarColor = (i?: number | string) => {
  const userAvatarColors = ['#95A2B3', '#717F87', '#FF5454', '#3FBC75']
  const getIndexFromString = (str: string) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    const index = Math.abs(hash)
    return index
  }
  const index =
    typeof i === 'number'
      ? i % userAvatarColors.length
      : typeof i === 'string'
        ? getIndexFromString(i) % userAvatarColors.length
        : Math.floor(Math.random() * userAvatarColors.length)
  return userAvatarColors[index]
}

export function getLast30DaysStartAndEndDates(): { start: string; end: string } {
  const start_date = moment().subtract(30, 'd').format('YYYY-MM-DD')
  const end_date = moment().format('YYYY-MM-DD')
  return {
    start: start_date,
    end: end_date
  }
}

export function createMagicLinkFromToken(magicToken: string) {
  const url = new URL(window.location.origin)
  url.searchParams.set('magic_token', magicToken)
  return url
}

export async function sendMagicLinkToEmail(email: string) {
  await DefaultService.userSendMagicLinkUserSendMagicLinkPost({ userEmail: email })
}

export const numberAbbreviation = {
  currency: (value: number, noDecimals?: boolean) => {
    const absValue = Math.abs(value)
    const sign = value < 0 ? '-' : ''
    return absValue >= 1000000
      ? `${sign}$${formatNumber(absValue / 1000000, { maxFractionDigits: 2 })}M`
      : absValue >= 1000
        ? `${sign}$${formatNumber(absValue / 1000, { maxFractionDigits: 2 })}K`
        : `${formatCurrency(value, { maxFractionDigits: noDecimals ? 0 : 2 })}`
  },
  percentage: (value: number, decimals?: number) => (decimals ? `${formatNumber(value, { maxFractionDigits: decimals })}%` : `${value}%`),
  default: (value: number, noDecimals?: boolean) => {
    const absValue = Math.abs(value)
    const sign = value < 0 ? '-' : ''
    return absValue >= 1000000
      ? `${sign}${formatNumber(absValue / 1000000, { maxFractionDigits: 2 })}M`
      : absValue >= 1000
        ? `${sign}${formatNumber(absValue / 1000, { maxFractionDigits: 2 })}K`
        : formatNumber(value, { maxFractionDigits: noDecimals ? 0 : 2 })
  }
}

export const formatMinutes = (minutes: number, obj?: { large?: boolean; includeSeconds?: boolean; includeMinutes?: boolean; restrictTwo?: boolean }) => {
  const { large, includeMinutes, includeSeconds, restrictTwo } = obj
  const duration = moment.duration(minutes, 'minutes')
  const days = duration.days()
  const hours = duration.hours()
  const mins = duration.minutes()
  const secs = duration.seconds()
  const milliseconds = duration.milliseconds()

  const arr = [
    days > 0 ? `${days}${large ? ' ' + pluralize('day', days) : 'd'}` : '',
    hours > 0 ? `${hours}${large ? ' ' + pluralize('hr', hours) : 'h'}` : '',
    includeMinutes && mins > 0 ? `${mins}${large ? ' ' + pluralize('min', mins) : 'm'}` : '',
    includeSeconds && secs > 0 ? `${secs}${large ? ' sec' : 's'}` : ''
  ].filter((e) => !!e)

  const result = restrictTwo ? arr.slice(0, 2) : arr
  if (result.length > 0) {
    return result.join(' ')
  } else {
    return `${`${secs + milliseconds / 1000}${large ? ' sec' : 's'}`}`
  }
}

export const truncateText = (text: string, length: number) => {
  if (text.length <= length) {
    return text
  }

  let truncated = text.substring(0, length)
  const lastSpaceIndex = truncated.lastIndexOf(' ')

  if (lastSpaceIndex !== -1) {
    truncated = text.substring(0, lastSpaceIndex)
  }

  return truncated + '...'
}

export function generateKey(parentKey: string, subNavKey?: string) {
  if (typeof parentKey !== 'string' || (subNavKey && typeof subNavKey !== 'string')) {
    throw new Error('Invalid input: parentKey and subNavKey must be strings')
  }
  if (!subNavKey) {
    return parentKey.startsWith('/') ? parentKey : `/${parentKey}`
  }

  return subNavKey.startsWith('/') ? subNavKey : parentKey.startsWith('/') ? `${parentKey}/${subNavKey}` : `/${parentKey}/${subNavKey}`
}

export function isLoopUser(currentUser: UserDataTypes | UserType | User) {
  return (
    get(currentUser, 'email', '').includes(`@${LOOPKITCHENXYZ_DOMAIN}`) ||
    get(currentUser, 'email', '').includes(`@${LOOPAIXYZ_DOMAIN}`) ||
    get(currentUser, 'email', '').includes(`@${TRYLOOPAI_DOMAIN}`)
  )
}

export function isInternalUser(currentUser: UserDataTypes | UserType | User) {
  return get(currentUser, 'internal', false)
}

export function encodeEmail(email: string) {
  return email
    ?.replace(/\./g, '_dot_')
    .replace(/\//g, '_slash_')
    .replace(/\\/g, '_backslash_')
    .replace(/\[/g, '_leftbracket_')
    .replace(/\]/g, '_rightbracket_')
    .replace(/\*/g, '_asterisk_')
}

export const formatDates = (startDateIn: string, endDateIn: string) => {
  const startDate = moment(startDateIn)
  const endDate = moment(endDateIn)
  const sameYear = startDate.year() === endDate.year()

  return {
    startDate: sameYear ? startDate.format('D MMM') : startDate.format('D MMM, YYYY'),
    endDate: sameYear ? endDate.format('D MMM') : endDate.format('D MMM, YYYY')
  }
}
