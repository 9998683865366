import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Toolbar } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import loopLogo1 from 'src/assets/logos/logo1.png'
import StoreLogo from 'src/components/StoreLogo'
import { useAuth } from 'src/context/AuthContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import StyledIcon from 'src/layout/components/StyledIcon'
import StyledLogo from 'src/layout/components/StyledLogo'
import { AccessLevelEnum } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { getAccessLevel } from 'src/utils/functions/accessLevel'

export default function SideBarHeader(): JSX.Element {
  const { currentUser, orgConfig } = useAuth()
  const { theme, open, toggleDrawer } = usePrivateLayoutDrawerContext()

  const accessLevel = useMemo(() => {
    return getAccessLevel(currentUser)
  }, [currentUser])

  return (
    <>
      <Box
        sx={{
          my: '10px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: open ? 'space-between' : 'center',
            flexDirection: open ? 'row' : 'column',
            width: '100%',
            gap: open ? 3 : 2,
            ml: open ? '15px' : 'auto',
            mr: open ? '35px' : 'auto',
            my: '10px'
          }}>
          {get(currentUser, 'org') &&
            (open ? (
              <>
                <Link
                  to="/home"
                  style={{
                    textDecoration: 'none'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    {accessLevel === AccessLevelEnum.DEMO || !get(orgConfig, 'logo', null) ? (
                      <StoreLogo showName />
                    ) : (
                      <Tooltip
                        title={orgConfig.name}
                        arrow
                        placement="top"
                        sx={{ width: '100%' }}>
                        <img
                          src={orgConfig.logo}
                          style={{
                            width: '100%',
                            height: 40,
                            objectFit: 'contain'
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Link>

                <StyledLogo
                  theme={theme}
                  sx={{
                    display: open ? 'flex' : 'none',
                    m: 0,
                    height: 40,
                    objectFit: 'contain'
                  }}
                  src={loopLogo1}
                  open={open}
                />
              </>
            ) : (
              <>
                <StyledLogo
                  theme={theme}
                  sx={{
                    display: 'flex',
                    m: 0,
                    height: 40,
                    objectFit: 'contain'
                  }}
                  src={loopLogo1}
                  open={open}
                />
                <Link
                  to="/home"
                  style={{
                    textDecoration: 'none'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    {get(currentUser, 'org') && <StoreLogo showAvatarOnly />}
                  </Box>
                </Link>
              </>
            ))}
        </Box>

        <Toolbar sx={{ position: 'absolute', right: '-7px' }}>
          <StyledIcon
            size="small"
            onClick={toggleDrawer}>
            {!open ? <ChevronRightIcon sx={{ color: 'white' }} /> : <ChevronLeftIcon sx={{ color: 'white' }} />}
          </StyledIcon>
        </Toolbar>
      </Box>
    </>
  )
}
