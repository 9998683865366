import { collection, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore'
import React from 'react'
import { UserDataTypes } from 'src/pages/members/User/Mangement/UserManagement'
import { db, getAllAccessLevels } from 'src/services/firebase'
import { DefaultService, SubChainData, SubChainSetRequest } from 'src/services/openApiV2'
import { skipAbortController } from 'src/services/openApiV2/utils/functions'
import { sendSlackNotification } from 'src/utils/api'
import { get } from 'src/utils/config/lodashUtils'
import { isInternalUser, isLoopUser } from 'src/utils/functions'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { v4 as uuid } from 'uuid'
import { useAuth } from './AuthContext'
import { AccessLevelEnum, UserType } from './AuthContext.type'
import { useErrorData } from './ErrorContext'

export interface SubChainModules {
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  modules: { uid: string; type?: 'preview' | 'live' }[]
  name: string
  uid?: string
}

export interface SubChainOrgUser {
  [user_uid: string]: SubChainData[]
}

interface SubChainContextInterface {
  userAlreadyMappedToOtherSubChain: (uid: string, subChainName: string) => boolean
  createSubChain: (obj: { subChainName: string; selectedLocationIds: number[]; selectedUsers: UserType[]; editData?: SubChainData }) => Promise<void>
  mapUsersToSubChains: (sub_chains: string[], user_id_list: string[]) => Promise<void>
  getAllSubChains: () => Promise<SubChainData[]>
  removeUsersFromSubChains: (user_id_list: string[]) => Promise<void>
  getAllUserAndSubChainRelation: () => SubChainOrgUser
  currentUserSubChainList: SubChainData[]
  allUsersAndSubChainRelation: {
    [uid: string]: SubChainData[]
  }
  allSubChains: SubChainData[]
  currentUserAccessibleSubChainList: SubChainData[]
  initialLoading: boolean
  getFilteredUserList: (allUsersData: UserType[] | UserDataTypes[], isDemo: boolean) => UserDataTypes[]
  deleteSubChain: (subChainName: string) => Promise<void>
  getSubchainOrgUsers: (org?: string) => Promise<SubChainOrgUser>
  getSubchainModules: () => Promise<{
    [uid: string]: SubChainModules
  }>
  setSubchainModules: (data: Partial<SubChainModules>) => Promise<void>
}

const SubChainContext = React.createContext({} as SubChainContextInterface)

export const useSubChainContext = () => React.useContext(SubChainContext)

export default function SubChainContextProvider(props: { children: React.ReactNode }) {
  const { currentUser, subChainData } = useAuth()
  const { handleError } = useErrorData()
  const [allUsersAndSubChainRelation, setAllUsersAndSubChainRelation] = React.useState<{ [uid: string]: SubChainData[] }>({})
  const [allSubChains, setAllSubChains] = React.useState<SubChainData[]>([])
  const [initialLoading, setInitialLoading] = React.useState(false)

  const currentUserSubChainList = React.useMemo(() => {
    const subChainList = get(allUsersAndSubChainRelation, currentUser?.uid, [] as SubChainData[])
    if (!Array.isArray(subChainList)) {
      return []
    }
    return subChainList
  }, [currentUser, allUsersAndSubChainRelation])

  const currentUserAccessibleSubChainList = React.useMemo(() => {
    const currentUserAccessLevel = getAccessLevel(currentUser)
    if (currentUserAccessLevel === AccessLevelEnum.BUSINESS_ADMIN) {
      return allSubChains
    }
    return allSubChains.filter((item) => currentUserSubChainList.some((e) => e.id === item.id))
  }, [currentUserSubChainList, allSubChains, currentUser])

  const userAlreadyMappedToOtherSubChain = React.useCallback(
    (uid: string, subChainName: string) => {
      const subChains = get(allUsersAndSubChainRelation, uid, [])
      return Array.isArray(subChains) && subChains.length > 0 && subChains.some((e) => e.name !== subChainName)
    },
    [allUsersAndSubChainRelation]
  )

  const getAllSubChains = React.useCallback(async () => {
    try {
      if (![AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.BUSINESS_MANAGER, AccessLevelEnum.INTERNAL_OPERATOR].includes(getAccessLevel(currentUser))) {
        setAllSubChains([])
        return []
      }
      const res = await DefaultService.getSubchainsSubchainGetAllGet({ chain: get(currentUser, 'org', '') })
      setAllSubChains(res)
      return res
    } catch (err) {
      handleError(err.message)
      return []
    }
  }, [currentUser])

  const getAllUserAndSubChainRelation = React.useCallback(() => {
    if (Object.keys(allUsersAndSubChainRelation).length === 0) {
      setInitialLoading(true)
    }
    let result: { [uid: string]: SubChainData[] } = {}
    try {
      if (!subChainData) {
        return result
      }
      if (currentUser?.org) {
        result = subChainData
      } else {
        // Handle the case where currentUser.org is undefined
        setInitialLoading(false)
        return {}
      }

      for (const key in result) {
        result[key] = result[key].map((e) => allSubChains.find((item) => item.id === e.id)).filter((e) => !!e)
      }
      setAllUsersAndSubChainRelation(result)
      return result
    } catch (err) {
      handleError(err.message)
      return result
    } finally {
      setInitialLoading(false)
    }
  }, [allUsersAndSubChainRelation, allSubChains, currentUser?.org, initialLoading, subChainData])

  const mapUsersToSubChains = React.useCallback(
    async (sub_chains: string[], user_id_list: string[]) => {
      try {
        const res = await DefaultService.mapUsersToSubchainsSubchainMapUserPost({
          chain: get(currentUser, 'org', ''),
          requestBody: {
            user_id_list,
            sub_chains
          }
        })
      } catch (err) {
        console.error('Error in mapping users to subchains', err)
        throw err
      }
    },
    [currentUser?.org]
  )

  const removeUsersFromSubChains = React.useCallback(
    async (user_id_list: string[]) => {
      try {
        const res = await DefaultService.mapUsersToSubchainsSubchainMapUserPost({
          chain: get(currentUser, 'org', ''),
          requestBody: {
            user_id_list,
            sub_chains: []
          }
        })
      } catch (err) {
        console.error('Error in removing users from subchains', err)
        throw err
      }
    },
    [currentUser?.org]
  )

  const createSubChain = React.useCallback(
    async (obj: { subChainName: string; selectedLocationIds: number[]; selectedUsers: UserType[]; editData?: SubChainData }) => {
      const { subChainName, selectedLocationIds, selectedUsers, editData } = obj
      // ? to be added later
      // await createFirebaseSubChain(id)
      const prevData = (
        editData
          ? {
              name: editData.name,
              location_ids: editData.bnames?.map((e) => e.id) || [],
              user_ids: editData.users?.map((e) => e.user_id) || [],
              accounting_company_ids: editData.accounting_companies?.map((e) => e.company_id) || []
            }
          : {
              name: subChainName
            }
      ) as SubChainSetRequest
      await DefaultService.createSubchainSubchainSetPost({
        chain: get(currentUser, 'org', ''),
        requestBody: {
          ...prevData,
          name: subChainName,
          location_ids: selectedLocationIds,
          user_ids: selectedUsers.map((e) => e.uid)
        }
      })

      // await setSubchainModules({ name: subChainName, modules: categorizedRoutes.map((e) => ({ uid: e.category.key, type: 'live' })) })
      sendSlackNotification({
        message: `Sub-chain created by ${currentUser?.email}`,
        channel: 'fe-logs',
        title: `Sub-chain created:
      By: \`${currentUser?.email}\`
      User's Access Level: \`${getAccessLevel(currentUser)}\`
      For Org: \`${currentUser?.org}\`
      Name: \`${subChainName}\`
      `
      })
    },
    [currentUser?.email, currentUser?.org]
  )

  const deleteSubChain = async (subChainName: string) => {
    await DefaultService.deleteSubchainSubchainDeleteDelete({ chain: currentUser?.org, name: subChainName })
    await deleteDoc(doc(db, 'subchain_modules', subChainName))
    sendSlackNotification({
      message: `Sub-chain deleted by ${currentUser.email}`,
      channel: 'fe-logs',
      title: `Sub-chain deleted:
      By: \`${currentUser.email}\`
      User's Access Level: \`${getAccessLevel(currentUser)}\`
      For Org: \`${currentUser.org}\`
      Name: \`${subChainName}\`
      `
    })
  }

  const getSubchainModules = async () => {
    try {
      const res = await getDocs(collection(db, 'subchain_modules'))
      return res.docs
        .map((doc) => ({ uid: doc.id, ...doc.data() }) as SubChainModules)
        .reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {} as { [uid: string]: SubChainModules })
    } catch (err) {
      console.error(err)
      return {}
    }
  }

  const setSubchainModules = async (data: Partial<SubChainModules>) => {
    try {
      if (!data?.name) {
        handleError('Subchain is required')
        return
      }
      const uid = data.uid || uuid()
      await setDoc(doc(db, 'subchain_modules', uid), {
        ...data,
        uid: uid,
        org: currentUser?.org,
        updated_at: new Date().toISOString(),
        updated_by: currentUser?.email
      })
    } catch (err) {
      console.error(err)
      handleError('Something went wrong while setting modules')
    }
  }

  const getFilteredUserList = React.useCallback(
    (allUsersData: UserType[] | UserDataTypes[], isDemo: boolean) => {
      const arr = allUsersData || []
      const tempData = arr.filter((item) => !isLoopUser(item) && !isInternalUser(item))
      const loopUsers = arr.filter((item) => item?.internal)
      const tempUserList = currentUser?.internal ? arr : !isDemo ? tempData : loopUsers
      const currentUserIsBusinessAdmin = getAccessLevel(currentUser) === AccessLevelEnum.BUSINESS_ADMIN
      const currentUserIsBusinessManager = getAccessLevel(currentUser) === AccessLevelEnum.BUSINESS_MANAGER
      const currentUserSubChainList = get(allUsersAndSubChainRelation, currentUser?.uid, [] as SubChainData[]) || []
      const currentUserIsInternalOperator = getAccessLevel(currentUser) === AccessLevelEnum.INTERNAL_OPERATOR
      const currentUserIsOwnerView = getAccessLevel(currentUser) === AccessLevelEnum.OWNER_VIEW

      const filteredUserList = currentUserIsBusinessAdmin
        ? tempUserList
        : currentUserIsInternalOperator || currentUserIsOwnerView || currentUserIsBusinessManager
          ? tempUserList.filter(
              (item) =>
                item?.uid === currentUser?.uid ||
                (get(allUsersAndSubChainRelation, item.uid, [] as typeof currentUserSubChainList).some((item) =>
                  currentUserSubChainList.some((obj) => obj.id === item.id)
                ) &&
                  ((typeof item.access_level === 'string'
                    ? getAllAccessLevels(item.access_level)?.is_custom
                    : item.access_level?.some((e) => getAllAccessLevels(e)?.is_custom)) ||
                    get(item, 'access_level', AccessLevelEnum.BUSINESS_ADMIN).includes(AccessLevelEnum.STORE_MANAGER) ||
                    get(item, 'access_level', AccessLevelEnum.BUSINESS_ADMIN).includes(AccessLevelEnum.OBSERVER) ||
                    get(item, 'access_level', AccessLevelEnum.BUSINESS_ADMIN).includes(AccessLevelEnum.INTERNAL_OPERATOR)))
            )
          : tempUserList.filter(
              (item) =>
                item?.uid === currentUser?.uid ||
                get(allUsersAndSubChainRelation, item.uid, [] as typeof currentUserSubChainList).some((item) =>
                  currentUserSubChainList.some((obj) => obj.id === item.id)
                )
            )
      return filteredUserList
    },
    [allUsersAndSubChainRelation, currentUser]
  )

  React.useEffect(() => {
    const getData = async () => {
      if (
        currentUser &&
        currentUser?.org &&
        [AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.BUSINESS_MANAGER, AccessLevelEnum.INTERNAL_OPERATOR].includes(getAccessLevel(currentUser))
      ) {
        getAllSubChains()
      }
    }
    getData()
  }, [currentUser?.org, getAccessLevel(currentUser), getAllSubChains])

  React.useEffect(() => {
    const getData = async () => {
      if (
        currentUser &&
        currentUser?.org &&
        [AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.BUSINESS_MANAGER, AccessLevelEnum.INTERNAL_OPERATOR].includes(getAccessLevel(currentUser)) &&
        allSubChains.length > 0
      ) {
        getAllUserAndSubChainRelation()
      }
    }
    getData()
  }, [currentUser?.org, getAccessLevel(currentUser), getAllUserAndSubChainRelation, allSubChains])

  const contextValue = React.useMemo(() => {
    return {
      userAlreadyMappedToOtherSubChain,
      createSubChain,
      mapUsersToSubChains,
      getAllSubChains,
      removeUsersFromSubChains,
      getAllUserAndSubChainRelation,
      deleteSubChain,
      getSubchainOrgUsers,
      getSubchainModules,
      setSubchainModules,
      allUsersAndSubChainRelation,
      allSubChains,
      currentUserAccessibleSubChainList,
      initialLoading,
      currentUserSubChainList,
      getFilteredUserList
    }
  }, [
    userAlreadyMappedToOtherSubChain,
    createSubChain,
    mapUsersToSubChains,
    getAllSubChains,
    removeUsersFromSubChains,
    getAllUserAndSubChainRelation,
    allUsersAndSubChainRelation,
    allSubChains,
    currentUserAccessibleSubChainList,
    initialLoading,
    currentUserSubChainList,
    getFilteredUserList
  ])

  return <SubChainContext.Provider value={contextValue}>{props.children}</SubChainContext.Provider>
}

export async function getSubchainOrgUsers(org: string, skipAbort?: boolean) {
  try {
    const func = skipAbort ? skipAbortController(DefaultService.getAllUserSubChainsSubchainOrgUsersGet) : DefaultService.getAllUserSubChainsSubchainOrgUsersGet
    const res = await func({ chain: org })
    return res as SubChainOrgUser
  } catch (err) {
    console.error(err)
    return {}
  }
}
