import { AccountingType, LoopModule, PLATFORM } from '@LoopKitchen/heyapi-client'
import packageJson from '../../../package.json'

export const BASE_URL = 'app.tryloop.ai'
export const BASE_FRONTEND_URL = 'https://app.tryloop.ai'
export const BASE_API_URL = 'https://api.loopapplication.xyz'
export const BASE_API_HOST = 'api.loopapplication.xyz'
export const BASE_SUPERSET_URL = 'https://super.tryloop.ai'
export const BASE_FIREBASE_FUNCTIONS_URL = 'https://functions.tryloop.ai'
export const BASE_DOCS_URL = 'https://docs.tryloop.ai'
export const BASE_AUTH_URL = 'https://auth.tryloop.ai'

export const APP_NAME = packageJson.name
export const APP_VERSION = packageJson.version
export const AUTH_ENCRYPTION_KEY = 'RnJhbmtseVNwZWFraW5nVGhpc0lzUmFuZG9t'

export const CYPRESS_REGISTER_EMAIL = 'token+cypressregister@tryloop.ai'
export const CYPRESS_LOGIN_EMAIL = 'token+cypresslogin@tryloop.ai'
export const CYPRESS_APP_EMAIL = 'cypress@loopapplication.xyz'
export const CYPRESS_USER_MANAGEMENT_EMAIL = 'cypress_user_management@loopapplication.xyz'

export const LOOPKITCHENXYZ_DOMAIN = 'loopkitchen.xyz'
export const LOOPAIXYZ_DOMAIN = 'loopai.xyz'
export const TRYLOOPAI_DOMAIN = 'tryloop.ai'

export const LOOP_SUPPORT_EMAIL = 'support@tryloop.ai'

export const filterNames = {
  b_name: 'Location',
  vb_name: 'Brand',
  vb_platform: 'Marketplace',
  vb_address: 'Address',
  am_name: 'Account Manager',
  slug: 'Digital Stores'
}

export const filterIcons = {
  b_name: 'museum',
  vb_name: 'workspaces',
  vb_platform: 'store',
  slug: 'store'
}

export enum AccessLevelEnum {
  BUSINESS_ADMIN = 'business-admin',
  BUSINESS_MANAGER = 'business-manager',
  DEMO = 'demo',
  FINANCE_TEAM = 'finance-team',
  INTERNAL_OPERATOR = 'internal-operator',
  OWNER_VIEW = 'owner-view',
  STORE_MANAGER = 'store-manager',
  OBSERVER = 'observer'
}

export const Modules = {
  StoreAvailability: 'Guard',
  Chargebacks: 'Customer Refunds',
  Recover: 'Recover',
  TotalChargebacks: 'Customer Refunds',
  EnabledChargebacks: 'Loop Enabled Customer Refunds',
  Balance: 'Balance',
  WonDisputes: 'Won Disputes',
  LostSales: 'Unfulfilled Sales',
  ErrorCharges: 'Error Charges',
  Marketing: 'TruROI',
  ReviewsAndRatings: 'Re-Engage',
  UnfulfilledRefunds: 'Unfulfilled Refunds'
}

export const ModuleNameMapping = {
  [LoopModule.GUARD]: 'Guard',
  [LoopModule.TRUROI]: 'TruROI',
  [LoopModule.RECOVER]: 'Recover',
  [LoopModule.REENG]: 'Re-Engage',
  [LoopModule.BASE]: 'Base',
  [LoopModule.BALANCE]: 'Balance'
}

export const AccountingTypeNames = {
  [AccountingType.CASH]: 'Payouts Based',
  [AccountingType.ACCRUAL]: 'Transactions Based'
}

export const accessLevelConfig: Record<AccessLevelEnum, { label: string }> = {
  [AccessLevelEnum.BUSINESS_ADMIN]: {
    label: 'Business Admin'
  },
  [AccessLevelEnum.BUSINESS_MANAGER]: {
    label: 'Business Manager'
  },
  [AccessLevelEnum.DEMO]: {
    label: 'Demo'
  },
  [AccessLevelEnum.FINANCE_TEAM]: {
    label: 'Accounting'
  },
  [AccessLevelEnum.INTERNAL_OPERATOR]: {
    label: 'Franchise Admin'
  },
  [AccessLevelEnum.OWNER_VIEW]: {
    label: 'Owner View'
  },
  [AccessLevelEnum.STORE_MANAGER]: {
    label: 'Store Manager'
  },
  [AccessLevelEnum.OBSERVER]: {
    label: 'Observer'
  }
}

export const platformNames = {
  [PLATFORM.DOORDASH]: 'DoorDash',
  [PLATFORM.GRUBHUB]: 'Grubhub',
  [PLATFORM.UBER_EATS]: 'Uber Eats',
  [PLATFORM.EZ_CATER]: 'ezCater',
  [PLATFORM.OLO]: 'Olo',
  [PLATFORM.LUNCHBOX]: 'Lunchbox'
}

export const StateNameToAbbreviation = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'District of Columbia': 'DC',
  'American Samoa': 'AS',
  Guam: 'GU',
  'Northern Mariana Islands': 'MP',
  'Puerto Rico': 'PR',
  'United States Minor Outlying Islands': 'UM',
  'Virgin Islands, U.S.': 'VI'
}

export const StateAbbreviationToName = Object.fromEntries(Object.entries(StateNameToAbbreviation).map(([key, value]) => [value, key]))

export const swrRefreshIntervalMilliseconds = 45 * 60 * 1000 // 45 minutes
export const automaticallyUpdateFiltersDuration = 10 * 60 * 1000 // 10 minutes

export const blockedOrgIds = ['sandbox', 'worldwide']

export const blockedApiEndpointsForSandbox = [
  'bulkUploadBnamesV2BnamesBulkUploadV2Post',
  'createBnameBnamesPost',
  'updateDigitalStoresStoreMappingStoresUpdatePost',
  'updateBnameBnamesBnameIdPut',
  'deleteBnameBnamesBnameIdDelete',
  'bulkDeleteDigitalStoresFromListDigitalStoresBulkDeleteFromListPost',
  'bulkUploadDigitalStoresV2DigitalStoresBulkUploadV2Post',
  'restoreDeletedStoreStoreMappingDeletedRestorePost',
  'runCustomerOnboardingActionsOnboardingCustomerPost',
  'addLoginUsernameStoreMappingUsernameAddPost',
  'getStatusReasonsBulkGetBackendStatusReasonBulkPost',
  'refreshStoreAvailabilityStoreAvailabilityRefreshSlugPost',
  'setStoresStatusSetStoreStatusPost',
  'setStoresStatusBulkSetStoreOnlineBulkPost',
  'getStatusReasonsGetBackendStatusReasonGet',
  'stopCampaignsStopCampaignPost',
  'startCampaignStartCampaignPost',
  'inviteOrgUserOrgInviteUserPost',
  'triggerEmailDigestNotificationEmailDigestPost',
  'deleteOrgUserOrgUserUserEmailDelete',
  'getOrgUsersOrgUsersGet',
  'inviteOrgUserOrgInviteUserPost',
  'updateBnamesToUserAdminUpdateBnamesPut',
  'createCompanyCodatCreateCompanyPost',
  'getAccessTokensPlaidGetAccessTokensPost',
  'createLinkTokenPlaidCreateLinkTokenPost',
  'setAccessTokenPlaidSetAccessTokenPost',
  'generateReviewSummaryReviewsSummaryGeneratePost',
  'setUserNotificationConfigsNotificationConfigSetPost',
  'triggerPushJournalEntriesV2AccountingJournalEntriesTriggerPushV2Post',
  'updateCompanyCodatUpdateConnectionsPost',
  'createAccountingSageCompanySageCreateCompanyPost',
  'createAccountingNetsuiteCompanyNetsuiteCreateCompanyPost',
  'createAccountingR365CompanyR365CreateCompanyPost',
  'createAccountingFreetextCompanyFreetextCreateCompanyPost',
  'authorizeLinkAuthorizeGet',
  'setPlaidMappingsPlaidMappingsSetPost',
  'createLinkTokenPlaidCreateLinkTokenPost',
  'disconnectAccountPlaidDeleteAccountDelete',
  'setBnameMappingsAccountingBnameMappingsSetPost',
  'setAccountingCadenceConfigAccountingConfigurationSetPost',
  'setGlMappingsAccountingGlMappingsSetPost',
  'updateFreetextCompanyGlCodesFreetextCompanyGlCodesPost',
  'deleteAccountingCadenceConfigAccountingConfigurationDeleteDelete',
  'updateR365CompanyClassesR365CompanyClassesPost',
  'updateFreetextCompanyClassesFreetextCompanyClassesPost',
  'reviewReplyReviewReplyPost',
  'getItemMetricsCompetitionItemMetricsPost',
  'getStoresWithItemCompetitionItemStoresPost',
  'updateDigitalStoreDigitalStoresDigitalStoreIdPut',
  'deleteDigitalStoresDigitalStoresDigitalStoreIdDelete',
  'mapUsersToSubchainsSubchainMapUserPost', // assigns users to subchain
  'getAllUserSubChainsSubchainOrgUsersGet', // get all users for org, with assigned subchains
  'createSubchainSubchainSetPost', // create subchain
  'deleteSubchainSubchainDeleteDelete', // delete subchain
  'createOrUpdateChargebackConfigV2ConfigChargebackCreateOrUpdateV2Post'
]
