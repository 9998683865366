import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Loader from 'src/components/mui/Loader'
import { usePublicAuthContext } from 'src/context/PublicAuthContext'

export default function AuthLayout(): JSX.Element {
  const { loading } = usePublicAuthContext()

  return (
    <>
      <Loader loading={loading} />
      <Box
        sx={{
          visibility: loading ? 'hidden' : 'visible'
        }}>
        <Box component="main">
          <Outlet />
        </Box>
      </Box>
    </>
  )
}
